import { ImageAnchor } from '../provider/cloudshelf/graphql/generated/cloudshelf_types';

export function getSizedImageURL(
    url?: string,
    width?: number,
    height?: number,
    crop?: ImageAnchor,
): string | undefined {
    if (!url) {
        return undefined;
    }
    let sourceURL = url ?? '';
    const w = width || window.innerWidth;
    const h = height || window.innerHeight;

    if (sourceURL.startsWith('http://') || sourceURL.startsWith('https://')) {
        if (sourceURL.includes('imagedelivery.net')) {
            //cloudflare image
            sourceURL = sourceURL.replace('/public', '/');
            sourceURL = `${sourceURL}w=${w},h=${h},fit=scale-down,sharpen=1`;
        } else if (sourceURL.includes('cdn.shopify.com')) {
            //shopify image
            if (!sourceURL.includes('?')) {
                sourceURL = `${sourceURL}?`;
            }
            sourceURL = `${sourceURL}&width=${w}&height=${h}`;

            if (crop && crop !== ImageAnchor.None) {
                sourceURL = `${sourceURL}&crop=${crop}`;
            }
        }
    }

    return sourceURL;
}
