import React, { FC } from 'react';
import QRCode from 'qrcode.react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { DisplayOnlyTrackedHandleType } from '../../../../../services/TrackedURLService/DisplayOnlyTrackedHandleType.enum';
import { CloudshelfTrackedURLService } from '../../../../../services/TrackedURLService/CloudshelfTrackedURLService';
import { stripHandle } from '../../../../../utils/String.Util';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export interface QRProps {
    type: DisplayOnlyTrackedHandleType;
    link?: string;
    handle?: string;
    reducedMargin?: boolean;
}

const DisplayOnlyQR: FunctionalComponentWithChildren<QRProps> = props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const trackedURLService = useInjection<CloudshelfTrackedURLService>(DependencyType.CloudshelfTrackedURLService);
    const providerConfig = configService.providerConfig();
    let domain: string | undefined = undefined;

    const domainKvp = providerConfig.find(config => config.key === 'domain');

    if (domainKvp) {
        if (domainKvp.values.length > 0) {
            domain = domainKvp.values[0];
        }
    }

    if (
        (!domain || domain?.trim() === '') &&
        (props.type === DisplayOnlyTrackedHandleType.PRODUCT || props.type === DisplayOnlyTrackedHandleType.CATEGORY)
    ) {
        return null;
    }

    if (!props.link && !props.handle) {
        console.warn('DisplayOnlyQR incorrectly configured. No link or handle provided.');
        return null;
    }

    const realHandle = stripHandle(props.handle ?? '');

    let qrSize = 0;
    let width = 0;
    let height = 0;

    if (window.visualViewport) {
        width = window.visualViewport.width;
        height = window.visualViewport.height;
    } else {
        width = window.innerWidth;
        height = window.innerHeight;
    }

    if (width > height) {
        const whiteBlockSize = width * 0.2;
        qrSize = whiteBlockSize * 0.8;
    } else {
        const whiteBlockSize = height * 0.15;
        qrSize = whiteBlockSize * 0.8;
    }

    //Try and make a tracked URL
    let embeddedURL: string | undefined = undefined;
    if (props.handle) {
        embeddedURL = trackedURLService.generateDisplayOnlyURL(props.type, realHandle);
        if (!embeddedURL) {
            if (props.type === DisplayOnlyTrackedHandleType.PRODUCT) {
                embeddedURL = `${domain}/products/${realHandle}`;
            } else if (props.type === DisplayOnlyTrackedHandleType.CATEGORY) {
                embeddedURL = `${domain}/collections/${realHandle}`;
            } else {
                //Final fallback, do to route domain
                embeddedURL = `${domain}`;
            }
        }
    } else if (props.link) {
        console.log('props.link', props.link);
        embeddedURL = trackedURLService.generateDisplayOnlyURL(props.type, props.link);
        console.log('gen', embeddedURL);
        if (!embeddedURL) {
            embeddedURL = props.link;
        }
    }

    if (!embeddedURL) {
        console.warn('embeddedURL is undefined');
        return null;
    }

    return (
        <div className={`QRWrapper ${props.reducedMargin && 'QRWrapper__reducedMargin'}`}>
            <QRCode renderAs="svg" value={embeddedURL} size={qrSize} />
        </div>
    );
};

DisplayOnlyQR.defaultProps = {
    reducedMargin: false,
};

export default DisplayOnlyQR;
