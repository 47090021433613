import React, { useEffect } from 'react';
import { ButtonSize } from '../Button/Button';
import StyledText, { TextSize, TextStyle } from '../../StyledText/StyledText';
import { FunctionalComponentWithChildren } from '../../../../FCWithChildren';

export type OnSelected = (option: string | undefined) => void;
export type ButtonSelectableType = 'checkbox' | 'radio';

export interface ButtonSelectableProps {
    size: ButtonSize;
    forId?: string;
    name: string;
    label: string;
    icon?: React.ReactElement | null;
    suffix?: React.ReactElement | null;
    value: string;
    type?: ButtonSelectableType;
    selected?: boolean;
    disabled?: boolean;
    unavailable?: boolean;
    className?: string;
    onSelected: OnSelected;
    // smaller?: boolean;
    outlined?: boolean;
    preventChanges?: boolean;
    translate?: boolean;
}

const ButtonSelectable: FunctionalComponentWithChildren<ButtonSelectableProps> = ({
    forId,
    name,
    label,
    icon,
    value,
    type = 'checkbox',
    selected = false,
    disabled = false,
    className = '',
    onSelected,
    outlined = false,
    preventChanges = false,
    size,
    suffix,
}) => {
    let textSize = TextSize.Small;
    if (size === ButtonSize.MD) {
        textSize = TextSize.Medium;
    } else if (size === ButtonSize.LG) {
        textSize = TextSize.Large;
    }

    const [checked, setChecked] = React.useState(selected);
    const onChange = () => {
        if (preventChanges) {
            return;
        }

        onSelected(value);
        setChecked(!checked);
    };

    const classNames = ['ButtonSelectable', className, `ButtonSelectable--${size}`];
    if (disabled) {
        classNames.push('ButtonSelectable--disabled');
    }
    if (checked) {
        classNames.push('ButtonSelectable--selected');
    }

    //Remove because it was causing issues with SelectFirstVariant
    // useEffect(() => {
    //     if (preventChanges) {
    //         onSelected(value);
    //     }
    // }, []);

    useEffect(() => {
        if (selected !== checked) {
            setChecked(selected);
        }
    }, [selected]);

    return (
        <div className={classNames.join(' ')} key={`${value}_${forId ?? ''}`}>
            <label
                className={`ButtonSelectable__label ${outlined ? '' : 'ButtonSelectable__label__noFrame'}`}
                htmlFor={`${value}_${forId ?? ''}`}
            >
                <input
                    className="ButtonSelectable__input"
                    id={`${value}_${forId ?? ''}`}
                    type={type}
                    name={`${name}_${forId ?? ''}`}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                />
                <span>{icon}</span>
                <StyledText size={textSize} style={TextStyle.Body} noWrap className="ButtonSelectable__text">
                    {label}
                </StyledText>
                <span>{suffix}</span>
                {/*<span className={`ButtonSelectable__text ButtonSelectable__text--${size}`}>{label}</span>*/}
            </label>
        </div>
    );
};

ButtonSelectable.defaultProps = {
    preventChanges: false,
};

export default ButtonSelectable;
