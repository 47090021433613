import { List, Record, RecordOf } from 'immutable';
import ProductVariant from './ProductVariant';
import { ProductDefaultValues, ProductParams } from '../Product';

type ProductVariantsParams = {
    variants: List<ProductVariant>;
    descriptionHtml: string;
};
export type ProductVariants = RecordOf<ProductVariantsParams>;

export type ProductDetailsParams = ProductParams & ProductVariantsParams;
export type ProductDetails = RecordOf<ProductDetailsParams>;

const variantDefaultValues = {
    variants: List<ProductVariant>(),
    descriptionHtml: '',
};

export const makeProductVariants: Record.Factory<ProductVariantsParams> = Record(variantDefaultValues);
export const makeProductDetails: Record.Factory<ProductDetailsParams> = Record({
    ...variantDefaultValues,
    ...ProductDefaultValues,
});
