import { FC, useEffect, useState } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { MenuService, MenuStyle } from '../../../../../services/MenuService/MenuService';
import {
    FilterSelection,
    ProductFilteringService,
} from '../../../../../services/ProductServices/ProductFilteringService';
import { Category } from '../../../../../services/CategoryService/entities/Category';
import * as Sentry from '@sentry/react';
import { RoutesHelperService } from '../../../../../services/RoutesService/RoutesHelperService';
import { useHistory } from 'react-router-dom';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import Button, { ButtonSize } from '../../../../shared/inputs/Button/Button';
import { NAME_FILTER_ID } from '../../../../../provider/cloudshelf/filter/CloudshelfFilters';
import { useTranslation } from 'react-i18next';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

const AttractSearch: FunctionalComponentWithChildren = () => {
    const translationService = useTranslation();
    const history = useHistory();
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const [menuIsExpandable, setMenuIsExpandable] = useState(false);
    const [searchIsExpanded, setSearchIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [matchingProductsCount, setMatchingProductsCount] = useState(-1);
    const shouldRender = menuIsExpandable && searchIsExpanded;

    const updateMatchingCount = async (category: Category | undefined, filters: FilterSelection[]) => {
        try {
            if (!searchIsExpanded) {
                setMatchingProductsCount(-1);
                return;
            }

            const titleFilter = filters.find(filter => filter.definitionId === NAME_FILTER_ID);
            const titleFilterValue = titleFilter?.values?.[0] ?? '';
            if (titleFilter && titleFilterValue.length >= 3) {
                setIsLoading(true);
                const count = await filteringService.countMatchingProducts(
                    'Attract Search -> updateMatchingCount',
                    category,
                    filters,
                    true,
                );
                setMatchingProductsCount(count);
            } else {
                setMatchingProductsCount(-1);
            }
        } catch (err) {
            setMatchingProductsCount(-1);
            Sentry.captureException(err, {
                extra: {
                    operationName: 'updateMatchingCount',
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const menuStyleObserver = menuService.observeMenuStyleChange().subscribe(menuStyle => {
            setMenuIsExpandable(menuStyle === MenuStyle.BUTTON_EXPANDABLE_SEARCH);
        });

        const searchExpandedObserver = menuService.observeExpandedSearchChange().subscribe(isOpen => {
            setSearchIsExpanded(isOpen);
        });

        setMenuIsExpandable(menuService.menuStyle === MenuStyle.BUTTON_EXPANDABLE_SEARCH);
        setSearchIsExpanded(menuService.searchExpanded);

        const filterViewStateObserver = filteringService.observeFilterViewSelectionState();

        const filterViewStateObserverSubscription = filterViewStateObserver.subscribe(async filterSelection => {
            await updateMatchingCount(undefined, filterSelection);
        });

        return () => {
            menuStyleObserver.unsubscribe();
            searchExpandedObserver.unsubscribe();
            filterViewStateObserverSubscription.unsubscribe();
        };
    });

    const handleBackgroundClick = () => {
        if (shouldRender) {
            menuService.setExpandedSearchOpen(false);
        }
    };

    const handleShowSelectionClick = () => {
        if (matchingProductsCount === 0) {
            return;
        }

        eventsService.requestBannersClose();
        filteringService.commitSelection();
        const categoryProductsRoute = RoutesHelperService.toCategoryProductsViaHandle('INTERNAL_ALL');
        history.push(categoryProductsRoute);
    };

    return (
        <div className={`AttractSearch ${shouldRender && 'AttractSearch__show'}`} onClick={handleBackgroundClick}>
            {shouldRender && (
                <>
                    <Button
                        className={'AttractSearch__button'}
                        size={ButtonSize.LG}
                        onClick={handleShowSelectionClick}
                        disabled={matchingProductsCount === 0}
                        style={{ marginTop: `calc(3 * var(--responsive-reference-point))` }}
                    >
                        {isLoading ? (
                            'Loading...'
                        ) : (
                            <>
                                {matchingProductsCount === 0 && translationService.t('filters_view.no_products')}
                                {matchingProductsCount > 0 &&
                                    translationService.t('filters_view.show', { productsCount: matchingProductsCount })}
                                {matchingProductsCount === -1 && translationService.t('filters_view.show_all')}
                            </>
                        )}
                    </Button>
                </>
            )}
        </div>
    );
};

export default AttractSearch;
