import React from 'react';
import StyledText, { TextSize, TextStyle } from '../../../../../shared/StyledText/StyledText';
import PurchaseTick from '../../../../../icons/purchase_tick';
import Button, { ButtonSize, ButtonVariant } from '../../../../../shared/inputs/Button/Button';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import { SessionManagementService } from '../../../../../../services/SessionManagementService/SessionManagementService';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../../../services/RoutesService/Routes';
import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';

export const PurchaseSuccessView: FunctionalComponentWithChildren = () => {
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const history = useHistory();
    const handleFinished = () => {
        void sessionManagementService.endSession();
        history.push(Routes.CATEGORIES);
    };

    return (
        <div className="PurchaseSuccessView">
            <div className="PurchaseSuccessView__body">
                <div className="PurchaseSuccessView__body__text">
                    <StyledText
                        className="PurchaseSuccessView__body__green"
                        style={TextStyle.Heading}
                        size={TextSize.Hero}
                        translate
                    >
                        Successful Payment
                    </StyledText>
                    <StyledText
                        className="PurchaseSuccessView__body__green"
                        style={TextStyle.Body}
                        size={TextSize.Medium}
                        translate
                    >
                        Thank you for shopping with us!
                    </StyledText>
                </div>

                <div className={'PurchaseSuccessView__tick'}>
                    <PurchaseTick />
                </div>

                <div className="PurchaseSuccessView__finished">
                    <Button
                        size={ButtonSize.LG}
                        variant={ButtonVariant.PRIMARY}
                        className="PurchaseSuccessView__finished__button"
                        onClick={handleFinished}
                        translate
                    >
                        Finished
                    </Button>
                </div>

                {/*<div className={'PurchaseSuccessView__tempBranding'}>*/}
                {/*    <StyledText*/}
                {/*        // className="PurchaseSuccessView__body__green"*/}
                {/*        style={TextStyle.Body}*/}
                {/*        size={TextSize.Small}*/}
                {/*        translate*/}
                {/*    >*/}
                {/*        Payment Powered By*/}
                {/*    </StyledText>*/}
                {/*    <div className="PurchaseSuccessView__tempBranding__logos">*/}
                {/*        <img src="/logos/viva.png" />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};
