import React, { CSSProperties, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import QRCode from 'qrcode.react';
import _ from 'lodash';
import { RoutesHelperService } from '../../../../../../services/RoutesService/RoutesHelperService';
import { convertRemToPixels } from '../../../../../../utils/Responsive.Util';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import { BackButtonMode, MenuService, MenuStyle } from '../../../../../../services/MenuService/MenuService';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';

export const BrandingPage: FunctionalComponentWithChildren = () => {
    const history = useHistory();
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const [pageClasses, setPageClasses] = useState<string[]>(['BrandingPage']);
    const [qrContainerClasses, setQrContainerClasses] = useState<string[]>(['BrandingPage__qrContainer']);
    const [countdown, setCountdown] = useState(-1);
    let headerElement: JSX.Element | undefined = undefined;
    const headerClasses: string[] = ['BrandingPage__text'];
    const backgroundStyle: CSSProperties = {};
    let qrCodeSize = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) * 0.33;
    const minQrSize = convertRemToPixels(8);
    const maxQrSize = convertRemToPixels(15);
    if (qrCodeSize > maxQrSize) {
        qrCodeSize = maxQrSize;
    } else if (qrCodeSize < minQrSize) {
        qrCodeSize = minQrSize;
    }
    qrCodeSize -= 20;

    useEffect(() => {
        if (countdown === 0) {
            history.replace('/');
        }

        if (countdown !== -1) {
            const timeout = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);

            return () => clearTimeout(timeout);
        }
    }, [countdown]);

    useEffect(() => {
        if (!pageClasses.includes('BrandingPage__fadeIn')) {
            const state = _.cloneDeep(pageClasses);
            state.push('BrandingPage__fadeIn');
            setPageClasses(state);

            const state2 = _.cloneDeep(qrContainerClasses);
            state2.push('BrandingPage__qrContainer__bringTogether');
            setQrContainerClasses(state2);
        }
    }, [pageClasses]);

    useEffect(() => {
        menuService.setFilterButtonVisible(false);
        menuService.setMenuStyle(MenuStyle.BAR_AND_FILTER_BUTTON);
        menuService.setBackButtonMode(BackButtonMode.CLOSE);

        const subscriber = menuService.observeBackTapped().subscribe(() => {
            history.push(RoutesHelperService.toCategories());
        });
        return () => {
            subscriber?.unsubscribe();
        };
    }, [menuService, history]);

    headerElement = (
        <>
            Cloudshelf connects into eCommerce websites
            <br />
            to automatically create brilliant
            <br />
            in-store experiences
        </>
    );

    return (
        <div className={_.join(pageClasses, ' ')}>
            <div className="BrandingPage__background" style={backgroundStyle} />
            <div className={_.join(headerClasses, ' ')}>{headerElement}</div>
            <div className={_.join(qrContainerClasses, ' ')}>
                <div className="BrandingPage__qrContainer__qrHeader">Scan here to find out more</div>
                <div className="BrandingPage__qrContainer__wrapper">
                    <QRCode
                        className="BrandingPage__qrContainer__wrapper__qrCode"
                        renderAs="svg"
                        value={'https://cloudshelf.ai/'}
                        size={qrCodeSize}
                        level={'H'}
                    />
                </div>
            </div>
            <div className="BrandingPage__CSLogo">
                <img alt="Cloudshelf Logo" src="/logos/pink-logo.svg" height="75" width="75" />
            </div>
        </div>
    );
};
