import { injectable } from 'inversify';
import CurrencyService from '../CurrencyService/CurrencyService';
import { MoneyV2 } from '../../provider/shopify/graphql/generated/shopify_types';
import { LocalProductVariant } from '../ProductServices/LocalProduct';

@injectable()
export default class PriceService {
    convertLowestDenominationToFull(price: number): string {
        return CurrencyService.format(price / 100);
    }

    getPrice(variant?: LocalProductVariant, quantity = 1): string {
        if (!variant?.price) {
            return '';
        }

        return CurrencyService.format(variant.price * quantity);
    }

    getPriceFromMoneyV2(price?: MoneyV2) {
        if (!price) {
            return '';
        }

        return CurrencyService.format(price.amount);
    }

    getOriginalPrice(variant?: LocalProductVariant, quantity = 1): string {
        if (!variant?.originalPrice) {
            return '';
        }

        if (variant.originalPrice === 0) {
            CurrencyService.format(variant.price * quantity);
        }

        return CurrencyService.format(variant.originalPrice * quantity);
    }

    getFromPrice(minPrice: number, maxPrice: number, excludePrefix = false) {
        if (minPrice === maxPrice) {
            return CurrencyService.format(minPrice);
        }

        return excludePrefix ? CurrencyService.format(minPrice) : `From ${CurrencyService.format(minPrice)}`;
    }

    getPriceRange(minPrice: number, maxPrice: number): string {
        if (minPrice === maxPrice) {
            return CurrencyService.format(minPrice);
        }
        return `${CurrencyService.format(minPrice)} - ${CurrencyService.format(maxPrice)}`;
    }
}
