import ProductVariant, { ProductVariantParams } from '../../../services/ProductServices/variant/ProductVariant';
import ProductVariantOption from '../../../services/ProductServices/variant/ProductVariantOption';
import { ProductVariantFragment } from '../graphql/generated/shopify_types';
import { ProductVariantAvailability } from '../../../services/ProductServices/ProductVariantAvailability';

export default class ShopifyVariantParser {
    static parseMultiple(variants: ProductVariantFragment[] | undefined): ProductVariant[] {
        if (!variants || variants.length === 0) {
            return [];
        }
        return variants.map(variant => this.parse(variant));
    }

    static parse(variant: ProductVariantFragment): ProductVariant {
        const options = variant.node.selectedOptions.map(option => new ProductVariantOption(option));
        let availability = ProductVariantAvailability.InStock;
        if (!variant.node.availableForSale) {
            availability = ProductVariantAvailability.Unavailable;
        } else if (variant.node.currentlyNotInStock) {
            availability = ProductVariantAvailability.OnOrder;
        }

        const params: ProductVariantParams = {
            id: variant.node.id,
            name: variant.node.title,
            price: variant.node.priceV2.amount,
            originalPrice: variant.node.compareAtPriceV2?.amount ?? variant.node.priceV2.amount,
            image: variant.node.image?.originalSrc,
            sku: variant.node.sku ? variant.node.sku : undefined,
            options,
            availability,
            inventory: variant.node.quantityAvailable ?? 0,
        };

        return new ProductVariant(params);
    }
}
