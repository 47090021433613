import React, { CSSProperties, MouseEvent, TouchEvent, useCallback, useEffect, useState } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { BackButtonMode, MenuService } from '../../../services/MenuService/MenuService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export type OnModalClose = () => void;

export interface ModalProps {
    bodyClassName?: string;
    topMost?: boolean;
    isOpen: boolean;
    closeDisabled?: boolean;
    onClose?: OnModalClose;
    displayVariant?: 'standard' | 'compact' | 'overflow';
    isExpanded?: boolean;
    imageIndex?: number;
    setIsExpanded?: (isExpanded: boolean) => void;
    setImageIndex?: (imageIndex: number) => void;
    dontChangeMenu?: boolean;
}

const Modal: FunctionalComponentWithChildren<ModalProps> = ({
    bodyClassName,
    isOpen,
    topMost,
    onClose,
    children,
    displayVariant,
    closeDisabled,
    setIsExpanded,
    isExpanded,
    imageIndex,
    setImageIndex,
    dontChangeMenu,
}) => {
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const modalStyle: CSSProperties = {};
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    let height = 'auto';

    if (topMost) {
        modalStyle.zIndex = '999';
    }

    if (displayVariant === 'standard' || displayVariant === 'overflow') {
        height = '100%';
    }

    const wrapperClassname = displayVariant === 'compact' ? 'Modal__wrapperCompact' : 'Modal__wrapper';
    const bodyProps = useSpring({
        config: config.stiff,
        opacity: isModalOpen ? 1 : 0,
        height: isModalOpen ? height : '0%',
    });

    const onModalClose = useCallback(
        (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement> | undefined) => {
            e?.stopPropagation();
            e?.nativeEvent?.stopPropagation();

            if (!onClose) {
                return;
            }

            if (!isModalOpen) {
                return;
            }
            if (isExpanded && setIsExpanded) {
                setIsExpanded(false);
            } else {
                if (setImageIndex && imageIndex !== 0) {
                    setImageIndex(0);
                }
                menuService.setBackButtonMode(BackButtonMode.NONE);
                menuService.setFilterButtonVisible(true);
                setIsModalOpen(!isModalOpen);
                if (onClose) onClose();
            }
        },
        [isModalOpen, onClose, menuService, setIsModalOpen, onClose],
    );

    const onModalBodyClick = (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    useEffect(() => {
        setIsModalOpen(isOpen);
        if (!isOpen) {
            menuService.setFilterButtonVisible(true);
        }
    }, [isOpen]);

    useEffect(() => {
        const subscriber = menuService.observeBackTapped().subscribe(() => {
            onModalClose(undefined);
        });
        return () => {
            subscriber?.unsubscribe();
        };
    }, [menuService, onModalClose]);

    useEffect(() => {
        if (isModalOpen && (dontChangeMenu === undefined || dontChangeMenu === false)) {
            if (closeDisabled) {
                menuService.setBackButtonMode(BackButtonMode.NONE);
            } else {
                menuService.setBackButtonMode(BackButtonMode.CLOSE);
            }
            menuService.setFilterButtonVisible(false);
        }
    }, [isModalOpen, menuService, closeDisabled, dontChangeMenu]);

    return (
        <animated.div
            className={isExpanded ? 'Modal expanded' : 'Modal'}
            onMouseDown={onModalClose}
            onTouchStart={onModalClose}
            style={modalStyle}
        >
            <div className={wrapperClassname}>
                <animated.div
                    className={`Modal__body ${bodyClassName}`}
                    style={bodyProps}
                    onMouseDown={onModalBodyClick}
                    onTouchStart={onModalBodyClick}
                >
                    {children}
                </animated.div>
            </div>
        </animated.div>
    );
};

export default Modal;
