import { TileSize } from '../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { CheckoutAcquisitionOption } from '../services/ConfigurationService/types/config/CloudshelfEngineConfig';

export class AcquisitionOptionUtil {
    static getAcquisitionCost(acquisitionOption: CheckoutAcquisitionOption, basketValue: number): number {
        console.log('getAcquisitionCost', acquisitionOption, basketValue);
        if (acquisitionOption.priceType === 'fixed') {
            return acquisitionOption.price;
        } else if (
            acquisitionOption.priceType === 'basket_exceeds_value_free' &&
            acquisitionOption.basketValueExceeds
        ) {
            return basketValue >= acquisitionOption.basketValueExceeds ? 0 : acquisitionOption.price;
        }

        return acquisitionOption.price;
    }
}
