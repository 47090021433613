export type RequestPaymentResponsePayload = {
    success: boolean;
    uniqueTransactionId: string;
    extraData?: any;
};

export type RequestPaymentPayload = {
    money: number;
    uniqueTransactionId: string;
};

export type PrintReceiptPayload = {
    uniqueTransactionId: string;
    brand: string;
    address: string;
    deviceName: string;
    itemLines: {
        qty: number;
        sku: string;
        description: string;
        price: string;
        notes?: string[];
    }[];
    total: string;
};

export type typeCloudshelfBridge = {
    version: string;
    releaseType: string;
    platform: string;
    canUseOnDevicePayments: boolean;
    printReceipt: (payload: PrintReceiptPayload) => void;
    requestPayment: (payload: RequestPaymentPayload) => void;
    requestPaymentResponse: (payload: RequestPaymentResponsePayload) => void;
    currentPaymentStatus: 'idle' | 'payment_pending' | 'payment_success' | 'payment_error';
    openNativeSettings: () => void;
};

declare global {
    interface Window {
        CloudshelfBridge?: typeCloudshelfBridge;
    }
}

class CloudshelfBridgeError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'CloudshelfBridgeError';
    }
}

export class CloudshelfBridge {
    static isAvailable() {
        return window.CloudshelfBridge !== undefined;
    }

    static printReceipt(payload: PrintReceiptPayload) {
        if (CloudshelfBridge.isAvailable()) {
            window.CloudshelfBridge!.printReceipt(payload);
        }
    }

    static requestPayment(payload: RequestPaymentPayload) {
        if (CloudshelfBridge.isAvailable()) {
            window.CloudshelfBridge!.requestPayment(payload);
        }
    }

    static openNativeSettings() {
        if (CloudshelfBridge.isAvailable()) {
            window.CloudshelfBridge!.openNativeSettings();
        }
    }

    static setPaymentCallbackFunction(callback: (payload: RequestPaymentResponsePayload) => void) {
        if (CloudshelfBridge.isAvailable()) {
            window.CloudshelfBridge!.requestPaymentResponse = callback;
        }
    }

    static getCurrentPaymentStatus() {
        if (CloudshelfBridge.isAvailable()) {
            return window.CloudshelfBridge!.currentPaymentStatus;
        }

        throw new CloudshelfBridgeError(
            'Attempted to use getCurrentPaymentStatus without CloudshelfBridge being available.',
        );
    }

    static setCurrentPaymentStatus(status: 'idle' | 'payment_pending' | 'payment_success' | 'payment_error') {
        if (CloudshelfBridge.isAvailable()) {
            window.CloudshelfBridge!.currentPaymentStatus = status;
        }
    }

    static getVersion() {
        if (CloudshelfBridge.isAvailable()) {
            return window.CloudshelfBridge!.version;
        }
        throw new CloudshelfBridgeError('Attempted to use getVersion without CloudshelfBridge being available.');
    }

    static canUseOnDevicePayments() {
        if (CloudshelfBridge.isAvailable()) {
            //We temp retun true for the event unless the app is up to date.
            //TODO: After april, switch to false
            return window.CloudshelfBridge!.canUseOnDevicePayments ?? true;
        }
        throw new CloudshelfBridgeError(
            'Attempted to use canUseOnDevicePayments without CloudshelfBridge being available.',
        );
    }

    static getReleaseType() {
        if (CloudshelfBridge.isAvailable()) {
            return window.CloudshelfBridge!.releaseType;
        }
        throw new CloudshelfBridgeError('Attempted to use getReleaseType without CloudshelfBridge being available.');
    }

    static getPlatform() {
        if (CloudshelfBridge.isAvailable()) {
            return window.CloudshelfBridge!.platform;
        }
        throw new CloudshelfBridgeError('Attempted to use getPlatform without CloudshelfBridge being available.');
    }
}
