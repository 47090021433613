import ProductVariantOption from './ProductVariantOption';
import { ProductVariantAvailability } from '../ProductVariantAvailability';

export interface ProductVariantParams {
    id: string;
    name: string;
    price: number;
    originalPrice: number;
    sku?: string;
    image?: string;
    altText?: string;
    options: ProductVariantOption[];
    availability: ProductVariantAvailability;
    inventory: number;
}

export default class ProductVariant {
    private readonly _id: string;

    private readonly _name: string;

    private readonly _sku: string | undefined;

    private readonly _price: number;

    private readonly _originalPrice: number;

    private readonly _image: string | undefined;

    private readonly _options: ProductVariantOption[];

    private readonly _availability: ProductVariantAvailability;

    private readonly _altText: string | undefined;

    private readonly _inventory: number;

    constructor({
        id,
        name,
        price,
        image,
        options,
        availability,
        altText,
        originalPrice,
        sku,
        inventory,
    }: ProductVariantParams) {
        this._id = id;
        this._name = name;
        this._sku = sku;
        this._price = price;
        this._originalPrice = originalPrice;
        this._image = image;
        this._options = options;
        this._availability = availability;
        this._altText = altText;
        this._inventory = inventory;
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get sku(): string | undefined {
        return this._sku;
    }

    get price(): number {
        return this._price;
    }

    get originalPrice(): number {
        return this._originalPrice;
    }

    get isOnSale(): boolean {
        return this._originalPrice !== this._price && this._originalPrice > 0;
    }

    get image(): string | undefined {
        return this._image;
    }

    get options(): ProductVariantOption[] {
        return this._options;
    }

    get availability(): ProductVariantAvailability {
        return this._availability;
    }

    get altText(): string | undefined {
        return this._altText;
    }

    get inventory(): number {
        return this._inventory;
    }

    getDefaultOption(): ProductVariantOption | undefined {
        if (this._options.length === 0) {
            return undefined;
        }
        return this._options[0];
    }
}
