export enum StorageKey {
    STORAGE_VERSION = 'cloudshelfStorageVersion',
    CLOUDSHELF_CONFIG = 'cloudshelfConfig',
    CLOUDSHELF_PROVIDER_CONFIG = 'cloudshelfProviderConfigKvps',
    CLOUDSHELF_DEVICE_INFO = 'cloudshelf-device-info',
    CLOUDSHELF_ID = 'cloudshelfId',
    SALES_ASSOCIATE_ID = 'salesAssociateId',
    SALES_ASSOCIATE_EXPIRY = 'salesAssociateExpiry',
    STORED_PREVIEW_SCREENSIZE = 'storedPreviewScreenSize',
}
