import React, { FC, ReactElement, RefObject, useMemo } from 'react';
import { UseMeasureRef } from 'react-use/lib/useMeasure';
import {
    getGridGutterValue,
    getGridMinWidthInCMValue,
    getGridSmallestDimensionValue,
    getOneVh,
    getOneVw,
} from '../../../utils/Responsive.Util';
import Loader from '../Loader/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SpringConfigs } from '../../../types/SpringConfigs';
import { Flipper } from 'react-flip-toolkit';
import { ResponsiveGridItem } from './Item/ResponsiveGridItem';
import { TileSize } from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export interface ResponsiveGridProps {
    items: ResponsiveGridItemWithKey[];
    hasMoreItems?: boolean;
    onMoreItemsRequested?: () => void;
    firstNoneHeroItemRef?: UseMeasureRef<HTMLDivElement>;
    gridRef?: RefObject<HTMLDivElement>;
    gridSizes: ResponsiveGridSizes;
    footerItem?: ReactElement;
    shouldUseProductAnimations?: boolean;
    scrollableTarget?: string;
    setRowHeight?: boolean;
    setHomeRowHeight?: boolean;
    additionalClassName?: string;
}

const ResponsiveGrid: FunctionalComponentWithChildren<ResponsiveGridProps> = props => {
    const gridItems = useMemo(() => {
        if (props.items === undefined) {
            return [];
        }

        let hasUsedRef = false;
        let _ref: UseMeasureRef<HTMLDivElement> | undefined;

        return props.items.map(item => {
            if (!hasUsedRef && props.firstNoneHeroItemRef) {
                _ref = props.firstNoneHeroItemRef;
                hasUsedRef = true;
            }

            const itemClassNames = ['responsiveGrid__item', props.additionalClassName];

            if (item.size === TileSize.Wide) {
                itemClassNames.push('responsiveGrid__item__wide');
            } else if (item.size === TileSize.Tall) {
                itemClassNames.push('responsiveGrid__item__tall');
            } else if (item.size === TileSize.Hero) {
                itemClassNames.push('responsiveGrid__item__hero');
            }

            return (
                <ResponsiveGridItem
                    ref={_ref}
                    key={item.element.key}
                    className={itemClassNames.join(' ')}
                    // className="Grid__item"
                    disabled={false}
                    flipId={item.flipId}
                    useAnimations={props.shouldUseProductAnimations}
                >
                    {item.element}
                </ResponsiveGridItem>
            );
        });
    }, [props.items, props.shouldUseProductAnimations, props.firstNoneHeroItemRef]);

    const flipKey = useMemo(() => {
        if (props.items === undefined) {
            return '';
        }

        return props.items.map(item => item.flipId).join('');
    }, [props.items]);

    const requestMore = () => {
        if (props.onMoreItemsRequested) {
            props.onMoreItemsRequested();
        }
    };

    const styles: {
        gridAutoRows: undefined | string;
        paddingTop: undefined | number;
        paddingBottom: undefined | number;
    } = {
        gridAutoRows: undefined,
        paddingTop: undefined,
        paddingBottom: undefined,
    };

    if (props.setRowHeight) {
        styles.paddingBottom = props.gridSizes.gutterSize * 1.15;
        styles.paddingTop = props.gridSizes.gutterSize * 1.15;
        styles.gridAutoRows = `${Math.floor(props.gridSizes.rowSize)}px`;
    } else if (props.setHomeRowHeight) {
        styles.paddingBottom = props.gridSizes.gutterSize * 1.15;
        styles.paddingTop = props.gridSizes.gutterSize * 1.15;
        styles.gridAutoRows = `${Math.floor(props.gridSizes.homeRowSize)}px`;
    }

    console.log('grid responsive sizes:', props.gridSizes);
    console.log('grid styles:', styles);

    return (
        <div style={{ height: 'calc(100% - 0.5rem)', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <InfiniteScroll
                dataLength={gridItems.length}
                className={'responsiveGrid__InfiniteScroll'}
                next={requestMore}
                hasMore={props.hasMoreItems ?? false}
                loader={
                    <div
                        style={{
                            height: '200px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Loader variant="dark" />
                    </div>
                }
                scrollableTarget={props.scrollableTarget}
            >
                <Flipper
                    flipKey={flipKey}
                    staggerConfig={{
                        // the "default" config will apply to staggered elements without explicit keys
                        default: {
                            // default direction is forwards
                            reverse: false,
                            // default is .1, 0 < n < 1
                            speed: SpringConfigs.staggerSpeed,
                        },
                    }}
                    spring={{
                        stiffness: SpringConfigs.moveStiffness,
                        damping: SpringConfigs.moveDamping,
                        overshootClamping: true,
                    }}
                >
                    <div
                        className={'responsiveGrid__container'}
                        style={{
                            gridGap: `${props.gridSizes.gutterSize * 1.15}px ${props.gridSizes.gutterSize}px`,
                            gridTemplateColumns: `repeat(auto-fit, ${Math.floor(props.gridSizes.columnSize)}px)`,
                            ...styles,
                        }}
                    >
                        {gridItems}
                    </div>
                </Flipper>
            </InfiniteScroll>
            {/*{props.footerItem}*/}
        </div>
    );
};

export interface ResponsiveGridItemWithKey {
    flipId?: string;
    element: ReactElement;
    size?: TileSize;
}

export interface ResponsiveGridSizes {
    numGridColumns: number;
    gutterSize: number;
    columnSize: number;
    rowSize: number;
    homeRowSize: number;
}

export function generateGridProperties(
    parentContainer: RefObject<HTMLDivElement>,
    dpi: number,
    products = false,
    minColumns = 2,
    maxColumns = 10,
    tileModifier = 1,
): ResponsiveGridSizes {
    if (!parentContainer.current) {
        return {
            numGridColumns: 0,
            gutterSize: 0,
            columnSize: 0,
            rowSize: 0,
            homeRowSize: 0,
        };
    }

    const oneCmInPx = dpi / 2.54; //convert from dots per inch to dots per cm.
    let minColumnWidth = getGridMinWidthInCMValue() * oneCmInPx;
    // const singleGutterSize = oneCmInPx / 6; //use 1cm for all 5 gutters possible gutters

    let realOneVh = getOneVh(); // * 1.75;
    let realOneVw = getOneVw(); // * 1.75;

    realOneVh *= tileModifier;
    realOneVw *= tileModifier;

    let smallestDimension = realOneVh;
    if (realOneVw < smallestDimension) {
        smallestDimension = realOneVw;
    }

    const smallestDimensionMultiplied = smallestDimension * getGridSmallestDimensionValue();

    if (smallestDimensionMultiplied > minColumnWidth) {
        minColumnWidth = smallestDimensionMultiplied;
    }

    let numberOfColumns = Math.floor(parentContainer.current?.clientWidth / minColumnWidth);

    if (numberOfColumns < minColumns) {
        numberOfColumns = minColumns;
    } else if (numberOfColumns > maxColumns) {
        numberOfColumns = maxColumns;
    }

    const tileWidth = parentContainer.current?.clientWidth / numberOfColumns;
    const singleGutterSize = tileWidth * getGridGutterValue(products); //use 1cm for all 5 gutters possible gutters

    const totalGutterWidth = numberOfColumns - 1 * singleGutterSize;
    const totalWidthAvailable = parentContainer.current?.clientWidth - totalGutterWidth;
    numberOfColumns = Math.floor(totalWidthAvailable / minColumnWidth);

    if (numberOfColumns < minColumns) {
        numberOfColumns = minColumns;
    } else if (numberOfColumns > maxColumns) {
        numberOfColumns = maxColumns;
    }

    const colSize = minColumnWidth - minColumnWidth * getGridGutterValue();

    const rSize = Math.floor(colSize * 1.1146496815286624);

    return {
        numGridColumns: numberOfColumns,
        gutterSize: Math.floor(singleGutterSize),
        columnSize: colSize,
        rowSize: rSize,
        homeRowSize: colSize,
    };
}

export default ResponsiveGrid;
