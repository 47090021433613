import { StorageKey } from './StorageKeys.enum';
import { injectable } from 'inversify';
import _ from 'lodash';
import { FilterableProductVariant } from '../ProductServices/LocalProduct';
import { DexieDatabase } from './DexieDatabase';
import { LogUtil } from '../../utils/Logging.Util';
import { gzipCompress, gzipDecompress } from '../../utils/Compression.Util';

@injectable()
export class StorageService {
    private storage: Storage;
    private cache: { [key: string]: string } = {};

    constructor() {
        this.storage = window.localStorage;
    }

    public setup() {
        //Important to note that STORAGE_VERSION is the only item we store uncompressed
        const storageVersion = this.storage.getItem(StorageKey.STORAGE_VERSION);
        if (storageVersion === null) {
            // Upgrade from v1 to v2
            // In v1 we did not compress data in local storage
            // We are going to do that now from v2 onwards
            for (let storageIndex = 0; storageIndex < this.storage.length; storageIndex++) {
                const keyForIndex = this.storage.key(storageIndex);
                //We only want to compress if the following are true:
                // - The key is not null
                // - The key exists in the StorageKey enum (known cloudshelf keys); this is so we don't
                //      break any packages that may use LocalStorage
                // - The key is not the storage version key
                if (
                    keyForIndex &&
                    _.includes(Object.values(StorageKey), keyForIndex) &&
                    keyForIndex !== StorageKey.STORAGE_VERSION
                ) {
                    const uncompressedItem = this.storage.getItem(keyForIndex);
                    if (uncompressedItem) {
                        const compressed = gzipCompress(uncompressedItem);
                        this.storage.setItem(keyForIndex, compressed);
                        this.cache[keyForIndex] = compressed;
                    }
                }
            }
            this.storage.setItem(StorageKey.STORAGE_VERSION, 'v2');
            window.location.reload(); //Force reload to ensure we don't get any errors
        } else if (storageVersion === 'v2') {
            // v2 is the current version, so no upgrade needed
            // if we ever need to upgrade again the logic from
            // v2 -> v3 should go here
        }
    }

    public get(key: StorageKey, bypassCache?: boolean): string | undefined {
        // console.log(`[StorageService] Get key: ${key}, bypassCache: ${bypassCache}`);
        //get from the cache is bypassCache is false
        if (!bypassCache && this.cache[key]) {
            const cachedValue = this.cache[key];
            // console.log(`[StorageService] Get key(${key}) value from cache: ${cachedValue} `);
            return cachedValue;
        }

        const compressed = this.storage.getItem(key);
        // console.log(`[StorageService] Get key(${key}) value from storage: ${compressed}`);
        if (compressed) {
            const decompressed = gzipDecompress(compressed);
            // console.log(`[StorageService] Get key(${key}) decompressed: ${decompressed}`);
            return decompressed;
        }

        return undefined;
    }

    public put(key: StorageKey, value: string) {
        const compressed = gzipCompress(value);
        this.storage.setItem(key, compressed);
        this.cache[key] = value;
    }

    public delete(key: StorageKey) {
        this.storage.removeItem(key);
        delete this.cache[key];
    }

    public async putProductCache(cache: FilterableProductVariant[]) {
        try {
            const dexieDatabase = new DexieDatabase();
            await dexieDatabase.putCache(cache);
            dexieDatabase.close();
        } catch (err) {
            LogUtil.LogException('Error in StorageService.putProductCache', err);
        }
    }

    public async getProductCache() {
        try {
            const dexieDatabase = new DexieDatabase();
            const result = await dexieDatabase.getCache();
            dexieDatabase.close();
            return result;
        } catch (err) {
            LogUtil.LogException('Error in StorageService.getProductCache', err);
        }
        return [];
    }
}
