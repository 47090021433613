import React, { CSSProperties, useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import { PowerTileBackgroundType } from '../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import QRCode from 'qrcode.react';
import _ from 'lodash';
import { Ring } from '@uiball/loaders';
import Lottie from 'react-lottie-player';
import confettiJson from '../../Purchase/animations/confetti.json';
import {
    CloudshelfTrackedURLService,
    TrackedUrl,
} from '../../../../../../services/TrackedURLService/CloudshelfTrackedURLService';
import { PowerTileRouteParams, Routes } from '../../../../../../services/RoutesService/Routes';
import { RoutesHelperService } from '../../../../../../services/RoutesService/RoutesHelperService';
import { ConfigurationService } from '../../../../../../services/ConfigurationService/ConfigurationService';
import { convertRemToPixels } from '../../../../../../utils/Responsive.Util';
import { BackButtonMode, MenuService, MenuStyle } from '../../../../../../services/MenuService/MenuService';
import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';

export const PowerTilePage: FunctionalComponentWithChildren = () => {
    const cloudshelfTrackedURLService = useInjection<CloudshelfTrackedURLService>(
        DependencyType.CloudshelfTrackedURLService,
    );
    const history = useHistory();
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const { power_tile_id: powerTileId } = useParams<PowerTileRouteParams>();
    const [powerTile] = useState(configService.powerTiles.find(({ id }) => id === atob(powerTileId)));
    const [trackedURL, setTrackedURL] = useState<TrackedUrl | undefined>(undefined);
    const [trackedURLId, setTrackedURLId] = useState<string | undefined>(undefined);
    const [trackedURLScanned, setTrackedURLScanned] = useState<boolean>(false);
    const [pageClasses, setPageClasses] = useState<string[]>(['PowerTilePage']);
    const [qrContainerClasses, setQrContainerClasses] = useState<string[]>(['PowerTilePage__qrContainer']);
    const [countdown, setCountdown] = useState(-1);
    const [checkScan, setCheckScan] = useState(false);
    const routeMatch = useRouteMatch(Routes.POWER_TILE);

    let headerElement: JSX.Element | undefined = undefined;
    const headerClasses: string[] = ['PowerTilePage__text'];
    const backgroundStyle: CSSProperties = {};
    let qrCodeSize = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) * 0.33;
    const minQrSize = convertRemToPixels(8);
    const maxQrSize = convertRemToPixels(15);
    if (qrCodeSize > maxQrSize) {
        qrCodeSize = maxQrSize;
    } else if (qrCodeSize < minQrSize) {
        qrCodeSize = minQrSize;
    }
    qrCodeSize -= 20;

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (powerTile?.qrCodeURL) {
            cloudshelfTrackedURLService
                .generateTrackedURL(powerTile.qrCodeURL)
                .then(trackedURL => {
                    timeout = setTimeout(() => {
                        if (trackedURL) {
                            setTrackedURL(trackedURL);
                            setTrackedURLId(trackedURL.url.split('/').pop());
                            setTrackedURLScanned(false);
                        } else {
                            //If the URL could not be tracked, lets just allow the user to go directly to it
                            setTrackedURL({ url: powerTile.qrCodeURL ?? '', id: '' });
                            setTrackedURLId(undefined);
                            setTrackedURLScanned(false);
                        }
                    }, 250);
                })
                .catch();
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);

    useEffect(() => {
        if (trackedURLId) {
            const timeout = setTimeout(async () => {
                const wasScanned = await cloudshelfTrackedURLService.isTrackedUrlQRScanned(trackedURLId);
                if (wasScanned) {
                    setTrackedURLScanned(true);
                }
                setCheckScan(!checkScan);
            }, 1000);

            return () => {
                clearTimeout(timeout);
                setCheckScan(!checkScan);
            };
        }
    }, [trackedURLId, checkScan]);

    useEffect(() => {
        if (countdown === 0) {
            history.replace('/');
        }

        if (countdown !== -1) {
            const timeout = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);

            return () => clearTimeout(timeout);
        }
    }, [countdown]);

    useEffect(() => {
        if (!pageClasses.includes('PowerTilePage__fadeIn')) {
            const state = _.cloneDeep(pageClasses);
            state.push('PowerTilePage__fadeIn');
            setPageClasses(state);

            const state2 = _.cloneDeep(qrContainerClasses);
            state2.push('PowerTilePage__qrContainer__bringTogether');
            setQrContainerClasses(state2);
        }
    }, [pageClasses]);

    useEffect(() => {
        menuService.setFilterButtonVisible(false);
        menuService.setMenuStyle(MenuStyle.BAR_AND_FILTER_BUTTON);
        menuService.setBackButtonMode(BackButtonMode.CLOSE);

        const subscriber = menuService.observeBackTapped().subscribe(() => {
            if (routeMatch?.isExact) {
                menuService.setBackButtonMode(BackButtonMode.NONE);
                history.push(RoutesHelperService.toCategories());
            }
        });
        return () => {
            subscriber?.unsubscribe();
        };
    }, [history, routeMatch, menuService]);

    if (!powerTile || !powerTile.qrCodeURL || _.isEmpty(powerTile.qrCodeURL.trim())) {
        console.log('Invalid power tile, redirecting to home page');
        history.replace('/');
        return null;
    }

    if (powerTile.backgroundType === PowerTileBackgroundType.SolidColour) {
        backgroundStyle.backgroundColor = powerTile.backgroundPrimaryColor;
    } else if (powerTile.backgroundType === PowerTileBackgroundType.Gradient) {
        backgroundStyle.background = `linear-gradient(${powerTile.backgroundPrimaryColor}, ${powerTile.backgroundSecondaryColor})`;
    } else if (powerTile.backgroundType === PowerTileBackgroundType.Image) {
        backgroundStyle.backgroundImage = `url(${powerTile.backgroundImage})`;
        backgroundStyle.backgroundSize = 'cover';
        backgroundStyle.filter = 'blur(8px)';
    } else if (powerTile.backgroundType === PowerTileBackgroundType.Transparent) {
        backgroundStyle.backgroundColor = 'black'; //Transparent causes issues
    }

    if (powerTile.useIcon) {
        headerElement = <i className={`${powerTile.icon}`} />;
        headerClasses.push('PowerTilePage__text__icon');
    } else {
        headerElement = <>{powerTile.title}</>;
    }

    return (
        <div className={_.join(pageClasses, ' ')}>
            <div className="PowerTilePage__background" style={backgroundStyle} />
            <div className={_.join(headerClasses, ' ')}>{headerElement}</div>
            <div className={_.join(qrContainerClasses, ' ')}>
                <div className="PowerTilePage__qrContainer__qrHeader">{powerTile.qrCodeText}</div>
                <div className="PowerTilePage__qrContainer__wrapper">
                    {trackedURL ? (
                        <>
                            {trackedURLScanned ? (
                                <Lottie
                                    className="PowerTilePage__qrContainer__wrapper__qrCode"
                                    animationData={confettiJson}
                                    onComplete={() => {
                                        setCountdown(1);
                                    }}
                                    play
                                    style={{ margin: 'auto', width: qrCodeSize, height: qrCodeSize }}
                                    loop={false}
                                />
                            ) : (
                                <QRCode
                                    className="PowerTilePage__qrContainer__wrapper__qrCode"
                                    renderAs="svg"
                                    value={trackedURL.url}
                                    size={qrCodeSize}
                                    level={'H'}
                                />
                            )}
                        </>
                    ) : (
                        <div
                            className="PowerTilePage__qrContainer__wrapper__qrCode"
                            style={{
                                width: qrCodeSize,
                                height: qrCodeSize,
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                        >
                            <Ring size={qrCodeSize / 2} lineWeight={2} speed={2} color="black" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
