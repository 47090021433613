import { CheckoutSupportedPaymentMethod } from '../Checkout.Supported.Payment.Method.enum';
import { BaseCheckoutService } from './BaseCheckoutService';
import { Basket } from '../../BasketService/Basket.type';
import { Checkout, CheckoutStyle } from '../Checkout.type';
import { BasketService } from '../../BasketService/BasketService';
import { ConfigurationService } from '../../ConfigurationService/ConfigurationService';
import { StorageService } from '../../StorageService/StorageService';

export class CloudshelfPlayerCheckoutService extends BaseCheckoutService {
    constructor(
        _basketService: BasketService,
        _configurationService: ConfigurationService,
        _storageService: StorageService,
    ) {
        super(_basketService, _configurationService, CheckoutStyle.ON_DEVICE, _storageService);
    }

    clear() {
        super.clear();
    }

    get supportsCoupons(): boolean {
        return false;
    }

    async handleBasketChange(basket: Basket | undefined): Promise<void> {
        if (!basket) {
            return;
        }

        let newCheckout: Checkout = {
            loading: true,
            appliedCouponCodes: [],
            subTotal: 0,
            total: 0,
        };

        //add up the price of the basket
        let subTotal = 0;
        let total = 0;
        basket.lineItems.forEach(item => {
            subTotal += item.quantity * item.variant.price;
            total += item.quantity * item.variant.price;
        });

        newCheckout = {
            loading: false,
            appliedCouponCodes: [],
            subTotal: subTotal,
            total: total,
        };
        this.propagateChanges(newCheckout);
    }

    async supportedPaymentMethods(): Promise<CheckoutSupportedPaymentMethod[]> {
        //We don't know what payment methods are supported by the third party accepting the checkout request
        return [
            CheckoutSupportedPaymentMethod.Visa,
            CheckoutSupportedPaymentMethod.Mastercard,
            CheckoutSupportedPaymentMethod.AndroidPay,
            CheckoutSupportedPaymentMethod.AndroidPay,
        ];
    }

    async getBasketId(): Promise<string | undefined> {
        return undefined;
    }
}
