import Dexie, { Table } from 'dexie';
import { FilterableProductVariant } from '../ProductServices/LocalProduct';

export const DEXIE_DATABASE_PRODUCT_BINARY_KEY = 'PRODUCT_BINARY';
export const DEXIE_DATABASE_PRODUCT_BINARY_KEY_v3 = 'PRODUCT_BINARY_V3';

export class CacheRecord {
    key = '';
    data: FilterableProductVariant[] = [];
}

export class DexieDatabase extends Dexie {
    cache!: Table<CacheRecord>;

    constructor() {
        super('CloudshelfDatabase');
        this.version(1).stores({ cache: 'key' });
    }

    public async getCache(): Promise<FilterableProductVariant[]> {
        const record = await this.cache.get(DEXIE_DATABASE_PRODUCT_BINARY_KEY_v3);
        if (record) {
            return record.data;
        }
        return [];
    }

    public async putCache(cache: FilterableProductVariant[]) {
        //Clearing is super quick even on super SHIT devices, so we can cheat here.
        this.cache.clear();
        const record = new CacheRecord();
        const key = DEXIE_DATABASE_PRODUCT_BINARY_KEY_v3;
        record.key = key;
        record.data = cache;
        await this.cache.put(record, key);
    }
}
