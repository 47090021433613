import { FC, useEffect, useState } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import {
    AttractLoopOrchestratorService,
    AttractLoopState,
} from '../../../../../services/AttractLoopOrchestratorService/AttractLoopOrchestratorService';
import AttractLoopScreen from './AttractLoopScreen';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import HomeIcon from '../../../../icons/home';
import { SessionManagementService } from '../../../../../services/SessionManagementService/SessionManagementService';
import { SessionEventType } from '../../../../../services/SessionManagementService/SessionEvent';
import SearchIcon from '../../../../icons/search';
import { MenuService } from '../../../../../services/MenuService/MenuService';

const NewBannerPlayer: FunctionalComponentWithChildren = () => {
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const [attractState, setAttractState] = useState<AttractLoopState>();
    const [visible, setVisible] = useState(false);
    const attractLoopOrchestratorService = useInjection<AttractLoopOrchestratorService>(
        DependencyType.AttractLoopOrchestratorService,
    );
    const playerVisible = attractState?.currentScreen.display ?? false;
    const [menuButtonsShown, setMenuButtonsShown] = useState(false);

    useEffect(() => {
        const sub = attractLoopOrchestratorService.observe().subscribe(state => {
            console.info(`AttractLoopOrchestratorService state`, state);
            setAttractState(state);
            const newVisible = state.currentScreen.display;
            setVisible(newVisible);
            console.log('new visible', newVisible);
            console.log('obeserved attractState', state);
        });

        setAttractState(attractLoopOrchestratorService.getState());

        const sub2 = sessionManagementService.observe().subscribe(state => {
            console.info(`SessionManagementService state`, state);

            if (state.type === SessionEventType.Started) {
                if (attractState?.currentScreen.def?.displayType === 'home') {
                    requestStopFromInteraction();
                }
            } else if (state.type === SessionEventType.Ended) {
                attractLoopOrchestratorService.restartFromSessionEnd();
            }
        });

        return () => {
            sub.unsubscribe();
            sub2.unsubscribe();
        };
    }, [attractLoopOrchestratorService, attractState]);

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined;
        if (attractState?.currentScreen.def?.displayHomeButton) {
            timeout = setTimeout(() => {
                setMenuButtonsShown(true);
            }, 2500);
        } else if (menuButtonsShown && !attractState?.currentScreen.def?.displayHomeButton) {
            setMenuButtonsShown(false);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [attractState, menuButtonsShown]);

    const requestStopFromInteraction = () => {
        console.log('requested stop via interaction');
        attractLoopOrchestratorService.requestStopFromInteraction();
    };

    const openSearchAndRequestStop = () => {
        console.log('requested stop via interaction and open search');
        menuService.setExpandedSearchOpen(true);
        requestStopFromInteraction();
    };

    return (
        <div
            className={`AttractLoop ${visible ? 'AttractLoop__visible' : 'AttractLoop__hidden'} ${
                attractState?.currentScreen.def?.displayHomeButton ? '' : 'AttractLoop__allowClickThrough'
            }`}
            onClick={requestStopFromInteraction}
        >
            {attractState?.availableScreens.map(screen => {
                if (screen.displayType === 'home') {
                    return null;
                }

                return (
                    <AttractLoopScreen
                        key={screen.id}
                        def={screen}
                        playerVisible={playerVisible}
                        animating={attractState?.currentScreen.def?.id === screen.id}
                        miniumTime={configService.config()?.attractLoop.screenMinimumDuration ?? 10}
                    />
                );
            })}

            <div className={`AttractLoop__Top ${menuButtonsShown ? 'AttractLoop__Top__Visible' : ''}`}>
                <button
                    className="Menu__roundButton AttractLoop__Top__HomeButton AttractLoopScreen__NewButtonStyle"
                    onClick={openSearchAndRequestStop}
                >
                    <SearchIcon />
                </button>
                <button
                    className="Menu__roundButton AttractLoop__Top__HomeButton AttractLoopScreen__NewButtonStyle"
                    onClick={requestStopFromInteraction}
                >
                    <HomeIcon />
                </button>
            </div>
        </div>
    );
};

export default NewBannerPlayer;
