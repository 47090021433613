import { CSSProperties, FC } from 'react';
import TimeBar from '../../../TimeBar/TimeBar';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';
import { DisplayOnlyEngineConfig } from '../../../../../../../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import { getSizedImageURL } from '../../../../../../../utils/ImageURL.Util';
import { FunctionalComponentWithChildren } from '../../../../../../../FCWithChildren';

export interface DisplayOnlyGalleryProps {
    config: Pick<DisplayOnlyEngineConfig, 'includeProductName' | 'includePrice' | 'includeStock' | 'includeQRCode'>;
    images: string[];
    activeImage: number;
}

const DisplayOnlyGallery: FunctionalComponentWithChildren<DisplayOnlyGalleryProps> = props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const imageStyle: CSSProperties = {};
    const imageTransformIndex = props.activeImage <= 0 ? 0 : props.activeImage;
    imageStyle.transform = `translateX(${imageTransformIndex * -100}%) translateZ(0)`;
    const maxWidth = screen.width * 1.5;
    const maxHeight = screen.height * 1.5;
    // Constrain maxWidth and maxHeight to 4k
    const constrainedMaxWidth = maxWidth > 3840 ? 3840 : maxWidth;
    const constrainedMaxHeight = maxHeight > 2160 ? 2160 : maxHeight;

    return (
        <div className={'DisplayOnlyGallery'}>
            <TimeBar
                millisecondsPerBlock={configService.displayOnlyConfig.timePerPhoto * 1000}
                numberOfBlocks={props.images.length}
                activeBlock={props.activeImage}
                forceFullWidth={
                    !props.config.includeProductName && !props.config.includePrice && !props.config.includeStock
                }
            />
            <div className={'DisplayOnlyGallery__images'} style={imageStyle}>
                {props.images.map((image, index) => {
                    return (
                        <div
                            className={`.DisplayOnlyGallery__images__imageWrapper ${
                                !props.config.includeProductName &&
                                !props.config.includePrice &&
                                !props.config.includeStock
                                    ? 'DisplayOnlyGallery__images__imageWrapper__noBar'
                                    : ''
                            }`}
                            key={index}
                        >
                            <img
                                src={getSizedImageURL(image, constrainedMaxWidth, constrainedMaxHeight)}
                                alt="product"
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DisplayOnlyGallery;
