import StockLabel from '../../StockLabel/StockLabel';
import React, { FC } from 'react';
import {
    getProductAvailability,
    getVariantAvailability,
    ProductVariantAvailability,
} from '../../../../../../services/ProductServices/ProductVariantAvailability';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../../../../services/ConfigurationService/ConfigurationService';
import _ from 'lodash';
import { LocalProduct, LocalProductVariant } from '../../../../../../services/ProductServices/LocalProduct';
import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';

export interface ProductAvailabilityProps {
    productDetails?: LocalProduct;
    selectedVariant?: LocalProductVariant;
    allVariantsUnavailable: boolean;
}
const ProductAvailability: FunctionalComponentWithChildren<ProductAvailabilityProps> = props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    let availabilityLabel: ProductVariantAvailability;

    let productAvailability: ProductVariantAvailability = ProductVariantAvailability.Unavailable;

    if (props.productDetails) {
        productAvailability = getProductAvailability(props.productDetails);
    }

    if (props.selectedVariant !== undefined) {
        availabilityLabel = getVariantAvailability(props.selectedVariant);
    } else {
        availabilityLabel = productAvailability;
    }

    const classNames = ['ProductAvailability__sectionStock'];
    if (props.selectedVariant?.sellableOnlineQuantity === 0) {
        classNames.push('ProductAvailability__unavailable');
    } else if (
        productAvailability === ProductVariantAvailability.LimitedAvailability &&
        !props.allVariantsUnavailable &&
        props.selectedVariant === undefined
    ) {
        classNames.push('ProductAvailability__limited');
    } else if (
        props.selectedVariant &&
        getVariantAvailability(props.selectedVariant) === ProductVariantAvailability.InStock
    ) {
        classNames.push('ProductAvailability__inStock');
    } else if (!props.selectedVariant && productAvailability === ProductVariantAvailability.InStock) {
        classNames.push('ProductAvailability__inStock');
    } else if (!props.selectedVariant) {
        classNames.push('ProductAvailability__unavailable');
    } else if (productAvailability === ProductVariantAvailability.OnOrder) {
        classNames.push('ProductAvailability__unavailable');
    }

    let content = [];

    if (configService.config()?.showTotalStockCount) {
        content.push(<StockLabel availability={availabilityLabel} />);
    }

    content = _.compact(content);

    if (content.length === 0) {
        return null;
    }

    return (
        <>
            <div className={'ProductAvailability__flex'}>{content}</div>
            <hr />
        </>
    );
};

export default ProductAvailability;
