import { Observable, Subject } from 'rxjs';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import DependencyType from '../../dependancyInjection/DependencyType';
import { inject, injectable } from 'inversify';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { BannerLinkType, ContentType, Size } from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import i18n from 'i18next';
import { EventsService } from '../EventsService/EventsService';
import { ulid } from 'ulidx';
import { insertEveryNth } from '../../utils/Array.Utils';

export type AttractLoopScreenButtonDef = {
    text: string;
    onClick: () => void;
};

export type AttractLoopScreenDef = {
    id: string;
    backgroundColor?: string;
    backgroundImage?: string;
    text: string[];
    button?: AttractLoopScreenButtonDef;
    displayHomeButton: boolean;
    textSize: Size;
    displayType: 'banner' | 'productGroup' | 'home';
};

export interface AttractLoopState {
    availableScreens: AttractLoopScreenDef[];
    currentScreen: {
        def?: AttractLoopScreenDef;
        index?: number;
        display: boolean;
    };
}

@injectable()
export class AttractLoopOrchestratorService {
    private eventsService: EventsService;
    private configService: ConfigurationService;
    private _homeScreenEndTimeout?: NodeJS.Timeout;
    private _interval?: NodeJS.Timeout;
    private _subject: Subject<AttractLoopState> = new Subject();
    private _availableScreens: AttractLoopScreenDef[] = [];
    private _displayState: AttractLoopState = {
        availableScreens: [],
        currentScreen: {
            display: false,
        },
    };
    private _nextAllowedAt?: Date;
    private _shouldForceClose = false;

    private displayMode: 'justHome' | 'screensUntilInteraction' = 'justHome';

    constructor(
        private readonly _configurationService: ConfigurationService,
        @inject(DependencyType.ApolloClient)
        private readonly apolloClient: ApolloClient<InMemoryCache>,
        private readonly _eventsService: EventsService,
    ) {
        this.configService = _configurationService;
        this.eventsService = _eventsService;
        this.setupQAHelpers();
    }

    getState() {
        return this._displayState;
    }

    setupQAHelpers() {
        (window as any).qaHelper_AttractLoopOrchestratorServiceToggle = () => {
            if (this._interval) {
                this.stop();
                console.info('Asked AttractLoopOrchestratorServiceToggle to stop');
            } else {
                this.start();
                console.info('Asked AttractLoopOrchestratorServiceToggle to start');
            }
        };
    }

    restartFromSessionEnd() {
        this._displayState.currentScreen.def = undefined;
        this._displayState.currentScreen.index = undefined;
        this._displayState.currentScreen.display = true;
        this._shouldForceClose = false;
        this._nextAllowedAt = new Date();
        this.start();
    }

    requestStopFromInteraction() {
        this._shouldForceClose = true;
        this.handleNext();
    }

    attractLoopReportHomeScreenTimeComplete() {
        console.info('[AttractLoopOrchestratorService] Home Screen Time Complete, allowing next.');
        this._nextAllowedAt = new Date();
    }

    start() {
        console.info(`[AttractLoopOrchestratorService] Start Called`);
        if (this._interval) {
            console.info('[AttractLoopOrchestratorService] start called but already started');
            return;
        }

        if (this._availableScreens.length === 0) {
            console.info('[AttractLoopOrchestratorService] start called but no screens');

            this._subject.next(this._displayState);
            return;
        }
        this._displayState.currentScreen = {
            display: false,
        };
        this._nextAllowedAt = new Date();

        this._interval = setInterval(() => {
            this.handleNext();
        }, 50);
    }

    stop() {
        console.info(`[AttractLoopOrchestratorService] Stop Called`);
        if (this._interval) {
            clearInterval(this._interval);
        }
        this._interval = undefined;

        if (this._homeScreenEndTimeout) {
            clearTimeout(this._homeScreenEndTimeout);
        }

        this._homeScreenEndTimeout = undefined;
    }

    observe(): Observable<AttractLoopState> {
        return this._subject.asObservable();
    }

    async reportBannerAnimatingOut() {
        console.log(
            '[AttractLoopOrchestratorService] A banner reported that it was animating out, so we can start the next animation',
        );
        this._nextAllowedAt = new Date();
    }

    async populateAttractLoop() {
        const isLandscape = window.innerHeight < window.innerWidth;

        const bannerScreens: AttractLoopScreenDef[] = [];
        const groupScreens: AttractLoopScreenDef[] = [];

        const config = this.configService.config();
        const bannersFromConfig = config?.banners ?? [];
        const groupContent = (config?.tiles ?? []).filter(tile => tile.type === ContentType.ProductGroup);

        //Temp, lets just add all the banners in order (I know that wrong)
        if (config?.attractLoop.includeBanners) {
            bannersFromConfig.map(banner => {
                let shouldDisplayHome = false;
                let image: string | undefined = undefined;

                if (isLandscape) {
                    //We try and use the landscape image, but if it doesn't exist we fallback to the vertical image.
                    image = banner.backgroundImageHorizontal;

                    if (!image) {
                        image = banner.backgroundImageVertical;
                    }
                } else {
                    //We try and use the vertical image, but if it doesn't exist we fallback to the horizontal image.
                    image = banner.backgroundImageVertical;

                    if (!image) {
                        image = banner.backgroundImageHorizontal;
                    }
                }
                let button: AttractLoopScreenButtonDef | undefined = undefined;

                if (banner.linkText?.trim() !== '') {
                    button = {
                        text: banner.linkText ?? '',
                        onClick: () => {
                            console.log('Attract Loop - Banner - On Clicked');
                            if (banner.linkType === BannerLinkType.Close || banner.linkText === BannerLinkType.None) {
                                //close, so do nothing?
                            } else if (banner.linkType === BannerLinkType.Product) {
                                this.eventsService.setOpenProduct(banner.linkProduct);
                            } else if (banner.linkType === BannerLinkType.ProductGroup) {
                                const prodGroup = this.configService.categories.find(
                                    c => c.handle === banner?.linkProductGroup,
                                );
                                if (prodGroup) {
                                    this.eventsService.setOpenCategory(prodGroup);
                                }
                            }
                            this.requestStopFromInteraction();
                        },
                    };
                }

                shouldDisplayHome = true;
                bannerScreens.push({
                    id: banner.id,
                    backgroundColor: banner.backgroundColour,
                    backgroundImage: image,
                    text: banner.bannerText,
                    button: button,
                    displayHomeButton: shouldDisplayHome,
                    textSize: this.configService.config()?.theme.attractLoopFontSize ?? Size.Regular,
                    displayType: 'banner',
                });
            });
        }

        if (bannerScreens.length === 1) {
            bannerScreens.push({
                ...bannerScreens[0],
                id: `${bannerScreens[0].id}-duplicate`,
            });
        }

        //Temp, lets just add all content in order (I know that wrong)
        if (config?.attractLoop.includeProductGroups) {
            groupContent.map(group => {
                if (!group.productGroupVisibleInAttractLoop) {
                    //We don't want to show this group in the attract loop
                    return;
                }
                let image: string | undefined = undefined;

                if (isLandscape) {
                    //We try and use the landscape image, but if it doesn't exist we fallback to the vertical image.
                    image = group.productGroupBackgroundImageHorizontal;

                    if (!image) {
                        image = group.productGroupBackgroundImageVertical;
                    }
                } else {
                    //We try and use the vertical image, but if it doesn't exist we fallback to the horizontal image.
                    image = group.productGroupBackgroundImageVertical;

                    if (!image) {
                        image = group.productGroupBackgroundImageHorizontal;
                    }
                }

                if (image === undefined) {
                    image = group.backgroundImage;
                }

                const button: AttractLoopScreenButtonDef | undefined = {
                    text: group.productGroupCallToAction?.trim() ?? '',
                    onClick: () => {
                        const prodGroup = this.configService.categories.find(c => c.handle === group?.handle);
                        if (prodGroup) {
                            console.log('Closing group banner and setting open category', prodGroup);
                            this.eventsService.setOpenCategory(prodGroup);
                        }
                        this.requestStopFromInteraction();
                    },
                };

                if (!group.productGroupCallToActionDisplayCTA) {
                    //If the CTA should not be displayed, we set the text to an empty string, and then we show the touch ripple
                    button.text = '';
                }

                groupScreens.push({
                    id: group.id,
                    backgroundColor: '#000000',
                    backgroundImage: image,
                    text: group.productGroupBannerText ?? [],
                    button: button,
                    displayHomeButton: true,
                    textSize: this.configService.config()?.theme.attractLoopFontSize ?? Size.Regular,
                    displayType: 'productGroup',
                });
            });
        }

        if (groupScreens.length === 1) {
            groupScreens.push({
                ...groupScreens[0],
                id: `${groupScreens[0].id}-duplicate`,
            });
        }

        let tempScreens: AttractLoopScreenDef[] = [];

        this._availableScreens = tempScreens;
        this._displayState.availableScreens = tempScreens;

        const homeEveryX = config?.attractLoop.placementX ?? -1;
        this.displayMode = 'justHome';
        if (config?.attractLoop.includeBanners || config?.attractLoop.includeProductGroups) {
            this.displayMode = 'screensUntilInteraction';

            let interleavedScreens: AttractLoopScreenDef[] = [];

            // if (config.attractLoop.includeHomeScreen) {
            //     if (config.attractLoop.placement === HomeScreenLoopPlacement.StartOfLoop) {
            //         interleavedScreens.push({
            //             id: `gid://cloudshelf/homescreen/noid`,
            //             displayHomeButton: false,
            //             displayType: 'home',
            //             text: [],
            //             textSize: this.configService.config()?.theme.attractLoopFontSize ?? Size.Regular,
            //         });
            //     } else {
            //         homeEveryX = config.attractLoop.placementX;
            //     }
            // }

            // Interleave product groups and banners
            for (let i = 0; i < Math.max(groupScreens.length, bannerScreens.length); i++) {
                if (i < groupScreens.length) {
                    interleavedScreens.push(groupScreens[i]);
                }
                if (i < bannerScreens.length) {
                    interleavedScreens.push(bannerScreens[i]);
                }
            }

            // Add any remaining screens that didn't get interleaved
            interleavedScreens = interleavedScreens.concat(
                groupScreens.slice(Math.max(groupScreens.length, bannerScreens.length)),
                bannerScreens.slice(Math.max(groupScreens.length, bannerScreens.length)),
            );

            // Add home screen at the specified interval if configured, so if homeEveryX is 3 then we have 2 interleavedScreens, then a home screen, then 2 interleavedScreens then a home screen etc.

            if (config.attractLoop.includeHomeScreen) {
                interleavedScreens = insertEveryNth(
                    interleavedScreens,
                    {
                        id: `gid://cloudshelf/homescreen/noid`,
                        displayHomeButton: false,
                        displayType: 'home',
                        text: [],
                        textSize: this.configService.config()?.theme.attractLoopFontSize ?? Size.Regular,
                    },
                    homeEveryX,
                );

                interleavedScreens.unshift({
                    id: `gid://cloudshelf/homescreen/noid`,
                    displayHomeButton: false,
                    displayType: 'home',
                    text: [],
                    textSize: this.configService.config()?.theme.attractLoopFontSize ?? Size.Regular,
                });
            }

            // Remove home screen if it's the last item
            if (interleavedScreens[interleavedScreens.length - 1].displayType === 'home') {
                interleavedScreens.pop();
            }

            tempScreens = interleavedScreens;
        }

        this._availableScreens = tempScreens;
        this._displayState.availableScreens = tempScreens;

        console.info('[AttractLoopOrchestratorService] Populated Attract Loop: ');
        console.info('[AttractLoopOrchestratorService] -> AvailableScreens', this._availableScreens);
        console.info('[AttractLoopOrchestratorService] -> DisplayMode', this.displayMode);
        console.info('[AttractLoopOrchestratorService] -> homeEveryX', homeEveryX);

        this.start();
    }

    async handleNext() {
        try {
            const newState: AttractLoopState = {
                ...this._displayState,
                currentScreen: {
                    ...this._displayState.currentScreen,
                },
            };

            if (this.displayMode === 'justHome' || this._availableScreens.length === 0) {
                //We should never show screens, so lets just return and do nothing.
                console.debug(`[AttractLoopOrchestratorService] Display Mode = justHome or no screens to show`);
                return;
            }

            if (this._shouldForceClose) {
                //  && this._displayState.currentScreen) {
                console.debug(`[AttractLoopOrchestratorService] Forcing close`);
                newState.currentScreen.display = false;
                console.info('publishing new state (force close)');
                this._subject.next(newState);
                this.stop();
                return;
            }

            if (this._nextAllowedAt && this._nextAllowedAt > new Date()) {
                return;
            }

            console.debug('[AttractLoopOrchestratorService] Handle next was allowed', this._displayState);

            //we always want to display the player, but we might want to now display the HomeButton
            newState.currentScreen.display = true;

            //Now lets move to the next banner in the list (or back to the start if we are at the end).
            const nextBannerIndex = (this._displayState.currentScreen?.index ?? -1) + 1;
            let nextScreen = undefined;
            if (this._displayState.availableScreens.length - 1 >= nextBannerIndex) {
                nextScreen = this._displayState.availableScreens[nextBannerIndex];
                newState.currentScreen.index = nextBannerIndex;
                newState.currentScreen.def = nextScreen;
            } else {
                nextScreen = this._displayState.availableScreens[0];
                newState.currentScreen.index = 0;
                newState.currentScreen.def = nextScreen;
            }

            if (nextScreen.displayType === 'home') {
                this._homeScreenEndTimeout = setTimeout(() => {
                    this.attractLoopReportHomeScreenTimeComplete();
                }, ((this.configService.config()?.attractLoop.screenMinimumDuration ?? 10) + 2) * 1000);
            }

            //Let's set the new state
            this._displayState = newState;

            //Let's set the time for the next update
            this._nextAllowedAt = new Date(Date.now() + 3600000); // Adding 1 hour in milliseconds

            //And finally let the subscribers know that we have updated the display state.
            console.info('publishing new state end');
            this._subject.next(this._displayState);
        } catch (err) {
            console.error('[AttractLoopOrchestratorService] ERROR!!', err);
        }
    }
}
