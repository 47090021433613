import React, { FC } from 'react';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import ProductsLoader from '../../../../shared/ProductsLoader/ProductsLoader';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { DisplayOnlyOrchestratorService } from '../../../../../services/DisplayOnlyOrchestratorService/DisplayOnlyOrchestratorService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import BaseServiceScreen from '../../../../shared/ServiceScreens/BaseServiceScreen/BaseServiceScreen';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export interface HandOffLoadingScreenProps {
    onSetupComplete?: () => void;
    explicitHandle?: string;
}

const HandOffLoadingScreen: FunctionalComponentWithChildren<HandOffLoadingScreenProps> = props => {
    const displayOnlyOrchestrator = useInjection<DisplayOnlyOrchestratorService>(
        DependencyType.DisplayOnlyOrchestratorService,
    );

    return (
        <BaseServiceScreen>
            <StyledText style={TextStyle.Heading} size={TextSize.Large} className={'HandOffLoadingScreen__text'}>
                Just a moment
            </StyledText>
            <div style={{ marginTop: '3vh' }}>
                <ProductsLoader
                    loadLocalCache={false}
                    explicitHandle={props.explicitHandle}
                    onComplete={() => props.onSetupComplete?.()}
                    onCachePopulated={() => {
                        displayOnlyOrchestrator
                            .populateDisplayState()
                            .then(() => {})
                            .catch(() => {});
                    }}
                />
            </div>
        </BaseServiceScreen>
    );
};

export default HandOffLoadingScreen;
