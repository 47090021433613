import { inject, injectable } from 'inversify';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { List } from 'immutable';
import { makeProductDetails, ProductDetails } from '../../../services/ProductServices/variant/ProductVariantService';
import { ShopifyDependencyTypes } from '../di/ShopifyDependencyTypes';
import {
    ShopifyProductDocument,
    ShopifyProductQuery,
    ShopifyProductQueryVariables,
} from '../graphql/generated/shopify_types';
import ShopifyVariantParser from './ShopifyVariantParser';
import _ from 'lodash';
import * as Sentry from '@sentry/react';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';

@injectable()
export class ShopifyVariantService {
    constructor(
        @inject(ShopifyDependencyTypes.ShopifyApolloClient)
        private readonly client: ApolloClient<NormalizedCacheObject>,
        @inject(DependencyType.ConfigurationService)
        private readonly configurationService: ConfigurationService,
    ) {}

    async fetchProductVariantsByProductId(handle: string): Promise<ProductDetails | undefined> {
        try {
            const { data } = await this.client.query<ShopifyProductQuery, ShopifyProductQueryVariables>({
                query: ShopifyProductDocument,
                variables: {
                    handle,
                },
                fetchPolicy: 'cache-first',
            });

            if (data.productByHandle) {
                let variantEdges = data.productByHandle.variants.edges;
                variantEdges = _.compact(
                    _.map(variantEdges, edge => {
                        if (
                            !this.configurationService.config()?.includeProductsOutOfStock &&
                            edge.node.currentlyNotInStock &&
                            !edge.node.availableForSale
                        ) {
                            return null;
                        }
                        return edge;
                    }),
                );
                const limitedAvailability = !_.every(variantEdges, v => v.node.availableForSale);
                const orderOnly = _.every(variantEdges, v => v.node.currentlyNotInStock);

                return makeProductDetails({
                    title: data.productByHandle.title,
                    availableForSale: data.productByHandle.availableForSale,
                    totalInventory: data.productByHandle.totalInventory ?? 0,
                    orderOnly: orderOnly,
                    limitedAvailability: limitedAvailability,
                    vendor: data.productByHandle.vendor,
                    handle,
                    id: data.productByHandle.id,
                    variants: List(ShopifyVariantParser.parseMultiple(variantEdges)),
                    descriptionHtml: data.productByHandle.descriptionHtml,
                    images: _.map(data.productByHandle.images.edges, image => ({
                        url: image.node.url as string,
                        variantIds: '',
                        preferred: false,
                    })),
                });
            }
            return undefined;
        } catch (ex) {
            Sentry.captureException(ex, {
                extra: {
                    operationName: 'fetchProductVariantsByProductId',
                },
            });
            return undefined;
        }
    }
}
