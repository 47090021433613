import React, { Suspense, useEffect, useState } from 'react';
import Loader from '../components/shared/Loader/Loader';
import { useContainer } from '../dependancyInjection/DependencyContext';
import { dependenciesContainer } from '../dependancyInjection/DependenciesInitializer';
import DependencyType from '../dependancyInjection/DependencyType';
import * as Sentry from '@sentry/react';
import FullPageErrorComponent from '../components/shared/FullPageError/FullPageError';
import { ConfigurationService } from '../services/ConfigurationService/ConfigurationService';
import { ECommercePlatform } from './cloudshelf/graphql/generated/cloudshelf_types';
import shopifyModule from './shopify/Module';
import { FunctionalComponentWithChildren } from '../FCWithChildren';

const ProviderModuleLoader: FunctionalComponentWithChildren = ({ children }) => {
    const configService = dependenciesContainer.get<ConfigurationService>(DependencyType.ConfigurationService);
    const observer = configService.observe();
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasFailed, setHasFailed] = useState(false);

    observer.subscribe(() => {
        setIsLoaded(false);
        setHasFailed(false);
    });

    const container = useContainer();

    const onLoaded = (successful: boolean) => {
        setHasFailed(!successful);
        setIsLoaded(true);
    };

    useEffect(() => {
        if (!isLoaded) {
            try {
                if (configService.config()?.eCommercePlatform === ECommercePlatform.Unknown) {
                    onLoaded(true);
                    return;
                }

                if (configService.config()?.eCommercePlatform === ECommercePlatform.Custom) {
                    onLoaded(true);
                    return;
                }

                if (configService.config()?.eCommercePlatform === ECommercePlatform.SalesforceB2C) {
                    onLoaded(true);
                    return;
                }

                if (configService.config()?.eCommercePlatform === ECommercePlatform.Shopify) {
                    container.load(shopifyModule);
                    onLoaded(true);
                }
            } catch (ex) {
                Sentry.captureException(ex, {
                    extra: {
                        operationName: 'loadProvider useEffect',
                    },
                });
                onLoaded(false);
            }
        }
    }, [isLoaded]);

    if (!isLoaded) {
        return <Loader variant="stars" />;
    }

    return (
        <Suspense fallback={<Loader variant="stars" />}>
            {hasFailed ? <FullPageErrorComponent variant="unknown" /> : <>{children}</>}
        </Suspense>
    );
};

export default ProviderModuleLoader;
