import {
    AttributeValue,
    CapitalisationStyle,
    FilterOrderType,
    FilterType,
} from '../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import {
    CloudshelfEngineFilter,
    EngineAttributeValue,
} from '../services/ConfigurationService/types/filters/CloudshelfEngineFilter';
import { FilterValueType } from '../services/ConfigurationService/types/filters/FilterValueType';
import { FilterableProductVariant } from '../services/ProductServices/LocalProduct';
import i18n from 'i18next';

export function getUniqAttributeItems(thisFilter: CloudshelfEngineFilter): EngineAttributeValue[] {
    return _.uniqBy(thisFilter.attributeValues, av => av.value);
}

export function getPriceRange(values: string[], places: number): string {
    if (values.length < 2) {
        if (values.length === 1) {
            return `£${parseFloat(values[0]).toFixed(places)}`;
        } else {
            return 'Unknown Price';
        }
    } else {
        return `£${parseFloat(values[0]).toFixed(places)}-£${parseFloat(values[1]).toFixed(places)}`;
    }
}

export function buildStockLevelFilter(
    includeInStock: boolean,
    includeProductsLimitedAvailability: boolean,
    includeOutOfStock: boolean,
    priority: number,
    name?: string,
): CloudshelfEngineFilter {
    const attributeValues: EngineAttributeValue[] = [];

    if (includeInStock) {
        attributeValues.push({
            priority: 0,
            value: 'In Stock',
            parentFilterId: null,
        });
    }

    if (includeProductsLimitedAvailability) {
        attributeValues.push({
            priority: 1,
            value: 'Order Only',
            parentFilterId: null,
        });
    }

    if (includeOutOfStock) {
        attributeValues.push({
            priority: 2,
            value: 'Out of Stock',
            parentFilterId: null,
        });
    }

    return {
        attributeValues: attributeValues,
        displayName: name === undefined ? i18n.t('filters.overrides.stock_level') : name,
        hiddenAttributeValues: [],
        ecommProviderFieldName: 'Stock',
        expandedByDefault: true,
        id: 'stock-level',
        type: FilterType.StockLevel,
        isHidden: false,
        isMergedChild: false,
        parentId: undefined,
        valueType: FilterValueType.DISCRETE,
        options: {
            __typename: 'FilterOptions',
            swatches: null,
            baseShoesizeUnit: null,
            capitalisationStyle: CapitalisationStyle.Original,
            orderType: FilterOrderType.Custom,
        },
        valueOverrides: [
            {
                displayValue: i18n.t('filters.overrides.in_stock'),
                originalValue: 'relevance',
                parentFilterId: null,
            },
            {
                displayValue: i18n.t('filters.overrides.order_only'),
                originalValue: 'price-desc',
                parentFilterId: null,
            },
            {
                displayValue: i18n.t('filters.overrides.out_of_stock'),
                originalValue: 'price-asc',
                parentFilterId: null,
            },
        ],
        priority: priority,
    };
}
export function buildOrderByFilter(): CloudshelfEngineFilter {
    return {
        attributeValues: [
            {
                priority: 0,
                value: 'relevance',
                parentFilterId: null,
            },
            {
                priority: 1,
                value: 'price-desc',
                parentFilterId: null,
            },
            {
                priority: 1,
                value: 'price-asc',
                parentFilterId: null,
            },
        ],
        displayName: i18n.t('filters.overrides.sort_by'),
        hiddenAttributeValues: [],
        ecommProviderFieldName: 'Sort By',
        expandedByDefault: true,
        id: 'sort-by',
        type: FilterType.SortOrder,
        isHidden: false,
        isMergedChild: false,
        parentId: undefined,
        valueType: FilterValueType.DISCRETE,
        options: {
            __typename: 'FilterOptions',
            swatches: null,
            baseShoesizeUnit: null,
            capitalisationStyle: CapitalisationStyle.Original,
            orderType: FilterOrderType.Custom,
        },
        valueOverrides: [
            {
                displayValue: i18n.t('filters.overrides.relevance'),
                originalValue: 'relevance',
                parentFilterId: null,
            },
            {
                displayValue: i18n.t('filters.overrides.price_high_low'),
                originalValue: 'price-desc',
                parentFilterId: null,
            },
            {
                displayValue: i18n.t('filters.overrides.price_low_high'),
                originalValue: 'price-asc',
                parentFilterId: null,
            },
        ],
        priority: -10,
    };
}

export function buildBuiltInSortOrder_Relevance(categoryHandle?: string): [keyof FilterableProductVariant, boolean][] {
    if (categoryHandle === undefined || categoryHandle === 'INTERNAL_ALL') {
        // console.log('using original relevance');
        return buildBuiltInSortOrder_RelevanceAllProducts();
    } else {
        // console.log('using shopify order for handle', `categoryOrderByHandles.${categoryHandle}`);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return [[`categoryOrderByHandles.${categoryHandle}`, false]];
    }
}

export function buildBuiltInSortOrder_RelevanceAllProducts(): [keyof FilterableProductVariant, boolean][] {
    return [
        ['availableForSale', true],
        ['remoteUpdatedAt', true],
    ];
}

export function buildBuiltInSortOrder_PriceAsc(): [keyof FilterableProductVariant, boolean][] {
    return [['price', false]];
}

export function buildBuiltInSortOrder_PriceDesc(): [keyof FilterableProductVariant, boolean][] {
    return [['price', true]];
}

export function buildBuiltInSortOrder_MostRecent(): [keyof FilterableProductVariant, boolean][] {
    return [['remoteUpdatedAt', true]];
}
