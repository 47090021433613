import { FilterableProductVariant, LocalProduct } from '../services/ProductServices/LocalProduct';
import _ from 'lodash';
import { gzipDecompress } from './Compression.Util';
import { CloudshelfEngineFilter } from '../services/ConfigurationService/types/filters/CloudshelfEngineFilter';
import { AttributeValueOverride, FilterType } from '../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { GlobalIDUtils } from './GlobalID.Util';

export function decompressProductBinaryAndMapToFilterableProductVariants(
    compressedProductBinary: string,
    filters: CloudshelfEngineFilter[],
): FilterableProductVariant[] {
    const decompressedBin = gzipDecompress(compressedProductBinary);
    const convertedData = JSON.parse(decompressedBin) as LocalProduct[];

    return MapToFilterableProductVariants(convertedData, filters);
}

export function MapToFilterableProductVariants(
    products: LocalProduct[],
    filters: CloudshelfEngineFilter[],
): FilterableProductVariant[] {
    const mappedVariants = _.map(products, (p): FilterableProductVariant[] => {
        const vars = _.map(p.variants, (v): FilterableProductVariant => {
            const options = v.options;

            const mappedOptions = _.map(options, opt => `${opt.key}:${opt.value}`);

            _.map(options, opt => {
                const filterForOption = filters.filter(f => f.ecommProviderFieldName === opt.key);
                if (filterForOption.length > 0) {
                    _.forEach(filterForOption, (filter: CloudshelfEngineFilter) => {
                        _.forEach(filter.valueOverrides, (override: AttributeValueOverride) => {
                            if (override.originalValue === opt.value) {
                                mappedOptions.push(`${opt.key}:${override.displayValue}`);
                            }
                        });
                    });
                }
            });

            const filterForVendor = filters.filter(f => f.type === FilterType.Vendor);
            if (filterForVendor.length > 0) {
                mappedOptions.push(`Vendor:${p.vendor}`);
                _.forEach(filterForVendor, (filter: CloudshelfEngineFilter) => {
                    _.forEach(filter.valueOverrides, (override: AttributeValueOverride) => {
                        if (override.originalValue === p.vendor) {
                            mappedOptions.push(`Vendor:${override.displayValue}`);
                        }
                    });
                });
            }

            const filterForProductType = filters.filter(f => f.type === FilterType.ProductType);
            if (filterForProductType.length > 0) {
                mappedOptions.push(`Product Type:${p.type}`);
                _.forEach(filterForProductType, (filter: CloudshelfEngineFilter) => {
                    _.forEach(filter.valueOverrides, (override: AttributeValueOverride) => {
                        if (override.originalValue === p.type) {
                            mappedOptions.push(`Product Type:${override.displayValue}`);
                        }
                    });
                });
            }

            const filterForTag = filters.filter(f => f.type === FilterType.Tag);
            if (filterForTag.length > 0) {
                mappedOptions.push(..._.map(p.tags, tag => `Tags:${tag}`));

                _.forEach(filterForTag, (filter: CloudshelfEngineFilter) => {
                    _.forEach(filter.valueOverrides, (override: AttributeValueOverride) => {
                        if (_.includes(p.tags, override.originalValue)) {
                            mappedOptions.push(`Tags:${override.displayValue}`);
                        }
                    });
                });
            }
            const metadataValues: string[] = [];
            const originalMetafieldValues = _.map(p.metadata, m => m.data);
            const filterForMetafields = filters.filter(
                f => f.type === FilterType.Metadata && _.find(p.metadata, mf => mf.key === f.metafieldKey),
            );

            _.forEach(originalMetafieldValues, (value: string) => {
                metadataValues.push(value);
            });

            _.forEach(filterForMetafields, (filter: CloudshelfEngineFilter) => {
                _.forEach(filter.valueOverrides, (override: AttributeValueOverride) => {
                    metadataValues.push(override.displayValue);
                });
            });

            const filterableVariant: FilterableProductVariant = {
                productId: p.id,
                variantId: v.id,
                eCommercePlatformVariantId: convertEcommercePlatformProvidedId(v.eCommercePlatformProvidedId) ?? v.id,
                eCommercePlatformProductId: convertEcommercePlatformProvidedId(p.eCommercePlatformProvidedId) ?? p.id,
                productHandle: p.handle,
                productTitle: p.title,
                categoryOrderByHandles: p.categoryOrderByHandles,
                categoryHandles: p.categoryHandles,
                categoryIds: p.categoryIds.map(c => `gid://cloudshelf/ProductGroup/${c}`),
                productType: p.type,
                productVendor: p.vendor,
                productTags: p.tags,
                remoteUpdatedAt: p.remoteUpdatedAt,
                productAvailableForSale: p.availableForSale,
                images: p.images,
                mappedOptions,
                metadataValues,
                productMetadata: p.metadata,
                position: v.position,
                ...v,
                options,
            };

            filterableVariant.productDescription = p.descriptionHtml;

            return filterableVariant;
        });

        return vars;
    });

    return _.flatten(mappedVariants);
}

export function convertEcommercePlatformProvidedId(id: string | undefined) {
    if (!id) {
        return id;
    }

    //its internal so we keep it as it is
    if (id.startsWith('gid://cloudshelf/')) {
        return id;
    }

    if (id.startsWith('gid://external/ShopifyProduct/')) {
        return id.replace('gid://external/ShopifyProduct/', 'gid://shopify/Product/');
    } else if (id.startsWith('gid://external/ShopifyProductVariant/')) {
        return id.replace('gid://external/ShopifyProductVariant/', 'gid://shopify/ProductVariant/');
    }

    //unknown
    return id;
}
