import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonCallback, PanelHeader } from '../PanelHeader/PanelHeader';
import { PurchaseQrCode } from './PurchaseQrCode';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { CheckoutService } from '../../../../../services/CheckoutService/CheckoutService';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import CardPaymentIcon from '../../../../icons/card_payment';
import {
    KnownPlugin,
    PaymentGenericPayload,
} from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { Waveform } from '@uiball/loaders';
import CloseIcon from '../../../../icons/close';
import { ulid } from 'ulidx';
import {
    GetPaymentTokenResponse,
    PaymentDemoService,
} from '../../../../../services/PaymentDemoService/PaymentDemoService';
import useStateRef from 'react-usestateref';
import { PurchaseStep, usePurchase } from '../../../../../hooks/contexts/Purchase/Purchase';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import { AcquisitionOptionUtil } from '../../../../../utils/AcquisitionOption.Util';
import CurrencyService from '../../../../../services/CurrencyService/CurrencyService';
import Button from '../../../../shared/inputs/Button/Button';
import PhoneScanQRIcon from '../../../../icons/phone_scan_qr';
import GridLoader from '../../../../shared/GridLoader/GridLoader';
import { StringUtils } from '../../../../../utils/String.Utils';
import FailedPersonIllustration from '../../../../illustrations /failed_person';
import SuccessPersonIllustration from '../../../../illustrations /success_person';
import _ from 'lodash';
import { SessionManagementService } from '../../../../../services/SessionManagementService/SessionManagementService';
import { BasketService } from '../../../../../services/BasketService/BasketService';

export interface PurchaseViewProps {
    onCloseButtonClicked: ButtonCallback;
}

export const NewPurchaseView: FunctionalComponentWithChildren<PurchaseViewProps> = ({ onCloseButtonClicked }) => {
    const { t } = useTranslation();
    const [hasScannedQrCode, setHasScannedQrCode] = React.useState(false);
    const checkoutService = useInjection<CheckoutService>(DependencyType.CheckoutService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const paymentDemoService = useInjection<PaymentDemoService>(DependencyType.PaymentDemoService);
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const [vivaPaymentToken, setVivaPaymentToken] = useState<GetPaymentTokenResponse | undefined>(undefined);
    const [paymentSession, setPaymentSession] = useState<string | undefined>(undefined);
    const [vivaCurrency, setVivaCurrency] = useState<string | undefined>(undefined);
    const [checkInterval, setCheckInterval, checkIntervalRef] = useStateRef<NodeJS.Timeout | undefined>(undefined);
    const purchaseContext = usePurchase();
    const [paymentType, setPaymentType] = useState<'card' | 'qr' | 'unknown' | undefined>(undefined);
    const [emailForReceipt, setEmailForReceipt] = useState<string | undefined>(undefined);
    const [emailForReceiptSaved, setEmailForReceiptSaved] = useState<boolean>(false);
    const [cardPaymentStatus, setCardPaymentStatus] = useState<
        'not_ready' | 'ready' | 'pending' | 'success' | 'failed' | undefined
    >('not_ready');
    const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | undefined>(undefined);
    const [getToken, setGetToken] = useState<number>(0);

    useEffect(() => {
        (async () => {
            const paymentSession = sessionManagementService.sanitizedSessionId;

            if (paymentSession === undefined) {
                setCardPaymentStatus('failed');
                setPaymentErrorMessage('Unable to start card payment without a valid session');
                return;
            }

            const plugin = configService.getKnownPlugin(KnownPlugin.AdditionalConfiguration);

            if (plugin) {
                const vars = plugin.variables;
                const vivaPassword = vars.find(v => v.key === 'VIVA_PASSWORD');
                const vivaCurrency = vars.find(v => v.key === 'VIVA_CURRENCY');

                if (
                    vivaPassword &&
                    vivaPassword.value.trim() !== '' &&
                    vivaCurrency &&
                    vivaCurrency.value.trim() !== ''
                ) {
                    const tokenResponse = await paymentDemoService.getPaymentToken(vivaPassword.value);
                    setVivaPaymentToken(tokenResponse);
                    setVivaCurrency(vivaCurrency.value);

                    console.log('token', tokenResponse.token);
                    if (!tokenResponse.success) {
                        setCardPaymentStatus('failed');
                        setPaymentErrorMessage(tokenResponse.message);
                    } else {
                        setCardPaymentStatus('ready');
                        setPaymentErrorMessage(undefined);
                    }
                }
            }
        })();
    }, [configService, paymentDemoService, getToken]);

    useEffect(() => {
        const options = configService.hardcodedCheckoutOptions();

        if (purchaseContext.checkoutAcquisitionOption) {
            //we need it.

            const acquisitionType = purchaseContext.checkoutAcquisitionOption.type;

            let canUseQR = false;
            let canUseCard = false;

            for (const option of options.cardPaymentAvailability) {
                if (option === acquisitionType) {
                    canUseCard = true;
                }
            }

            for (const option of options.qrPaymentAvailability) {
                if (option === acquisitionType) {
                    canUseQR = true;
                }
            }

            if (!canUseCard) {
                //we might still want to force QR
                if (
                    options.qrPaymentAvailability.includes('always') ||
                    options.qrPaymentAvailability.includes('if_no_other_option')
                ) {
                    canUseQR = true;
                }
            }

            if (canUseCard && canUseQR) {
                setPaymentType('unknown');
            } else if (canUseCard && !canUseQR) {
                setPaymentType('card');
            } else if (canUseQR && !canUseCard) {
                setPaymentType('qr');
            }
        }
    }, [configService, purchaseContext.checkoutAcquisitionOption]);

    if (!purchaseContext.checkoutAcquisitionOption) {
        //should not be possible
        return null;
    }

    const acquisitionCost = AcquisitionOptionUtil.getAcquisitionCost(
        purchaseContext.checkoutAcquisitionOption,
        checkoutService.totalPriceAsNumber,
    );

    const totalPrice = checkoutService.totalPriceAsNumber + acquisitionCost;

    const handleStartCardPayment = async () => {
        if (cardPaymentStatus === 'pending') {
            return;
        }
        setCardPaymentStatus('pending');
        // todo: use session id
        const paymentSession = sessionManagementService.sanitizedSessionId;

        if (paymentSession === undefined) {
            setCardPaymentStatus('failed');
            setPaymentErrorMessage('Unable to start card payment without a valid session');
            return;
        }

        const basketId = await checkoutService.getBasketId();

        if (!basketId) {
            setPaymentErrorMessage('Unable to start card payment without a valid basket id');
            setCardPaymentStatus('failed');
            return;
        }

        const startPaymentResult = await paymentDemoService.startPaymentRequest(
            paymentSession,
            vivaPaymentToken?.token ?? '',
            _.round(totalPrice * 100, 2),
            vivaCurrency ?? '',
        );

        if (!startPaymentResult.success) {
            setPaymentErrorMessage(startPaymentResult.message ?? 'test');
            setCardPaymentStatus('failed');
            return;
        }

        if (checkIntervalRef.current) {
            cancelInterval(checkIntervalRef.current);
        }

        const interval = setInterval(async () => {
            const result: PaymentGenericPayload = await paymentDemoService.getPaymentRequest(
                paymentSession ?? '',
                vivaPaymentToken?.token ?? '',
            );

            console.log('result', result);

            if (result.success) {
                cancelInterval(checkIntervalRef.current);
                setCheckInterval(undefined);
                const createPaidOrderResult = await paymentDemoService.createPaidOrder(
                    (await checkoutService.getBasketId())!,
                    paymentSession ?? '',
                    vivaPaymentToken?.token ?? '',
                    emailForReceipt ?? '',
                );

                if (createPaidOrderResult.success) {
                    setPaymentSession(undefined);
                    setCardPaymentStatus('success');
                } else {
                    setPaymentErrorMessage(createPaidOrderResult.message ?? '');
                    setCardPaymentStatus('failed');
                }
            } else {
                if (result.pending) {
                    console.log('transaction pending');
                } else if (result.cancelled) {
                    console.log('transaction cancelled');
                    cancelInterval(checkIntervalRef.current);
                    setPaymentSession(undefined);
                    setCheckInterval(undefined);
                    setCardPaymentStatus('failed');
                    setPaymentErrorMessage(result.message ?? '');
                } else {
                    console.log('transaction failed');
                    cancelInterval(checkIntervalRef.current);
                    setPaymentSession(undefined);
                    setCheckInterval(undefined);
                    setCardPaymentStatus('failed');
                    setPaymentErrorMessage(result.message ?? '');
                }
            }
        }, 2500);
        setCheckInterval(interval);
    };

    const handleCancelCardPayment = async () => {
        const result = await paymentDemoService.abortPaymentRequest(
            paymentSession ?? '',
            vivaPaymentToken?.token ?? '',
        );

        cancelInterval(checkIntervalRef.current);
        setPaymentSession(undefined);
        setCheckInterval(undefined);
        setCardPaymentStatus('failed');
    };

    useEffect(() => {
        console.log('newInterval', checkIntervalRef.current);
    }, [checkInterval]);

    const cancelInterval = (int: NodeJS.Timeout | undefined) => {
        console.log('cancelInterval', int);
        clearInterval(int);
    };

    const getOptionsUI = () => {
        if (paymentType === undefined) {
            return null;
        }

        if (paymentType === 'unknown') {
            //we need to let the user choose
            return (
                <>
                    <div className="NewPurchaseView__stackedOption">
                        <div className="NewPurchaseView__stackedOption__icon">
                            <CardPaymentIcon />
                        </div>
                        <Button
                            className={'NewPurchaseView__stackedOption__button'}
                            onClick={() => setPaymentType('card')}
                            translate
                        >
                            Pay By Card
                        </Button>
                    </div>
                    <div className="NewPurchaseView__stackedOption">
                        <div className="NewPurchaseView__stackedOption__icon">
                            <PhoneScanQRIcon />
                        </div>
                        <Button
                            className={'NewPurchaseView__stackedOption__button'}
                            onClick={() => setPaymentType('qr')}
                            translate
                        >
                            Pay By QR Code
                        </Button>
                    </div>
                </>
            );
        }

        if (paymentType === 'card') {
            return (
                <>
                    <div className="NewPurchaseView__sideStackedOption">
                        <div className="NewPurchaseView__receiptArea">
                            <div className="NewPurchaseView__receiptArea__inner">
                                <StyledText style={TextStyle.Subheading} size={TextSize.Large} translate>
                                    To get receipt
                                </StyledText>

                                <div className="NewPurchaseView__receiptArea__space"></div>

                                <div>
                                    <StyledText
                                        style={TextStyle.Body}
                                        size={TextSize.Small}
                                        translate
                                        className="NewPurchaseView__receiptArea__inputLabel"
                                    >
                                        Enter Email
                                    </StyledText>
                                    <input
                                        className={'NewPurchaseView__receiptArea__input'}
                                        type={'email'}
                                        value={emailForReceipt}
                                        onChange={e => setEmailForReceipt(e.target.value)}
                                        disabled={emailForReceiptSaved}
                                    />
                                </div>

                                {!emailForReceiptSaved && (
                                    <Button
                                        className={`NewPurchaseView__receiptArea__submitButton ${
                                            !(!StringUtils.validateEmail(emailForReceipt ?? '') || emailForReceiptSaved)
                                                ? 'NewPurchaseView__receiptArea__submitButton__enabled'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            if (!StringUtils.validateEmail(emailForReceipt ?? '')) {
                                                return;
                                            }

                                            setEmailForReceiptSaved(true);
                                        }}
                                        translate
                                        disabled={
                                            !StringUtils.validateEmail(emailForReceipt ?? '') || emailForReceiptSaved
                                        }
                                    >
                                        Submit
                                    </Button>
                                )}

                                {emailForReceiptSaved && (
                                    <StyledText className="NewPurchaseView__receiptArea__successText" translate>
                                        Receipt will be sent to {emailForReceipt}
                                    </StyledText>
                                )}
                            </div>
                        </div>
                        {(cardPaymentStatus === 'failed' || cardPaymentStatus === 'success') && (
                            <div className="NewPurchaseView__illustrationContainer">
                                <StyledText
                                    className={`NewPurchaseView__${cardPaymentStatus}`}
                                    style={TextStyle.Heading}
                                    size={TextSize.Large}
                                    translate
                                    align={'center'}
                                >
                                    {cardPaymentStatus === 'failed' && 'Payment Failed'}
                                    {cardPaymentStatus === 'success' && 'Payment Successful'}
                                </StyledText>
                                <StyledText
                                    className={`NewPurchaseView__${cardPaymentStatus}`}
                                    style={TextStyle.Subheading}
                                    size={TextSize.Medium}
                                    translate
                                    align={'center'}
                                >
                                    {cardPaymentStatus === 'success' && 'Thank you for shopping with us today!'}
                                    {cardPaymentStatus === 'failed' && paymentErrorMessage}
                                </StyledText>
                                <div className="NewPurchaseView__illustrationContainer__illustration">
                                    {cardPaymentStatus === 'failed' && <FailedPersonIllustration />}
                                    {cardPaymentStatus === 'success' && <SuccessPersonIllustration />}
                                </div>
                                {cardPaymentStatus === 'failed' && (
                                    <Button
                                        className={'NewPurchaseView__illustrationContainer__button'}
                                        onClick={() => {
                                            setCardPaymentStatus('ready');

                                            if (vivaPaymentToken === undefined || !vivaPaymentToken.success) {
                                                setGetToken(old => old + 1);
                                            }
                                        }}
                                        translate
                                    >
                                        Try Again
                                    </Button>
                                )}
                            </div>
                        )}
                        {cardPaymentStatus !== 'failed' && cardPaymentStatus !== 'success' && (
                            <div style={{ flex: 1, alignSelf: 'center' }}>
                                <div className="NewPurchaseView__stackedOption">
                                    <div className="NewPurchaseView__stackedOption__icon">
                                        {/* <CardPaymentIcon /> */}
                                        {cardPaymentStatus === 'pending' && <GridLoader />}
                                        {cardPaymentStatus !== 'pending' && <CardPaymentIcon />}
                                    </div>
                                    <Button
                                        className={'NewPurchaseView__stackedOption__button'}
                                        onClick={() => {
                                            if (cardPaymentStatus === 'ready') {
                                                handleStartCardPayment();
                                            } else {
                                                handleCancelCardPayment();
                                            }
                                        }}
                                        translate
                                        disabled={!emailForReceiptSaved || cardPaymentStatus === 'not_ready'}
                                    >
                                        {cardPaymentStatus === 'pending' ? 'Cancel' : 'Pay By Card'}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            );
        }

        if (paymentType === 'qr') {
            return (
                <>
                    <div className="NewPurchaseView__stackedOption">
                        <div
                            className={`NewPurchaseView__stackedOption__icon ${
                                hasScannedQrCode ? 'NewPurchaseView__stackedOption__icon__scanned' : ''
                            }`}
                        >
                            <PurchaseQrCode
                                onScan={() => setHasScannedQrCode(true)}
                                onCancelClearSession={onCloseButtonClicked}
                            />
                        </div>
                        {!hasScannedQrCode && (
                            <StyledText
                                className="NewPurchaseView__stackedOption__text"
                                style={TextStyle.Body}
                                size={TextSize.Small}
                                translate
                            >
                                Scan to pay by phone
                            </StyledText>
                        )}
                    </div>
                </>
            );
        }
    };

    return (
        <div className="NewPurchaseView">
            <PanelHeader
                onBackButtonClicked={() => purchaseContext.continue(PurchaseStep.BASKET)}
                // onCloseButtonClicked={onCloseButtonClicked}
                title={t('purchase_view.header.title', {
                    option: purchaseContext.checkoutAcquisitionOption?.displayName,
                })}
            />
            <div className="NewPurchaseView__body">
                {acquisitionCost > 0 && (
                    <div className="NewPurchaseView__acquisitionCost">
                        <StyledText
                            style={TextStyle.Body}
                            size={TextSize.Small}
                            className={'NewPurchaseView__acquisitionCost__title'}
                            translate
                        >
                            {purchaseContext.checkoutAcquisitionOption?.displayName}
                            {': '}
                        </StyledText>
                        <StyledText
                            style={TextStyle.Body}
                            size={TextSize.Small}
                            className={'NewPurchaseView__acquisitionCost__price'}
                            translate
                        >
                            {CurrencyService.format(acquisitionCost)}
                        </StyledText>
                    </div>
                )}
                <StyledText
                    className="NewPurchaseView__totalPrice"
                    style={TextStyle.Heading}
                    size={TextSize.Large}
                    translate
                >
                    {t('checkout_view.total')}
                    {': '}
                    <span className="NeverTranslate">{CurrencyService.format(totalPrice)}</span>
                </StyledText>

                <div className="NewPurchaseView__options">{getOptionsUI()}</div>
                <div className="NewPurchaseView__paymentIcons">
                    <img src="/paymentMethods/apple.png" alt="Apple Pay" />
                    <img src="/paymentMethods/google.png" alt="Google Pay" />
                    <img src="/paymentMethods/master.png" alt="Mastercard" />
                </div>
            </div>
        </div>
    );
};
