import React, { useEffect, useState } from 'react';
import { LocalProductMetadata } from '../../../../../../../services/ProductServices/LocalProduct';
import _ from 'lodash';
import Button, { ButtonOutlineVariant, ButtonSize, ButtonVariant } from '../../../../../../shared/inputs/Button/Button';
import format from 'date-fns/format';
import StyledText, { TextSize, TextStyle } from '../../../../../../shared/StyledText/StyledText';
import { SlidingModal, SlidingModalOrigin } from '../../../SlidingModal/SlidingModal';
import { addDays, addYears, parse, parseISO } from 'date-fns';
import axios from 'axios';
import ButtonSelectable from '../../../../../../shared/inputs/ButtonSelectable/ButtonSelectable';
import Loader from '../../../../../../shared/Loader/Loader';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { getMenuHeight } from '../../../Menu/Menu';
import CalendarIcon from '../../../../../../icons/calendar';
import { KeyValuePair, KnownPlugin } from '../../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import { useTranslation } from 'react-i18next';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';
import { DateUtils } from '../../../../../../../utils/Date.Utils';

export interface ProductBookThatAppSectionProps {
    variantId?: string;
    metadata: LocalProductMetadata[];
    onValidate: (isValid: boolean) => void;
    onChange: (values: KeyValuePair[]) => void;
    onLimitPurchaseQuantity: (quantity: number) => void;
    productHandle: string;
    showWarning?: boolean;
    showWarningsWithPristineData?: boolean;
}

export interface BTABlock {
    date: string;
    formattedDate: string;
    formattedDateCheckout: string;
    timeslots: BTATimeslot[];
    available_slot_count: number;
}

export interface BTATimeslot {
    formattedTime: string;
    start: string;
    finish: string;
    available: number;
    status: number;
}

export interface SelectedBTATimeslot {
    date: string;
    start: string;
    finish: string;
}

const ProductBookThatAppSection = React.forwardRef<HTMLElement, ProductBookThatAppSectionProps>((props, ref) => {
    const translationService = useTranslation();
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const btaFields = _.filter(props.metadata, mf => mf.key.startsWith('bookthatapp'));
    const [datePickerModalOpen, setDatePickerModalOpen] = React.useState<boolean>(false);
    const [btaStartDate, setBtaStartDate] = useState<Date | undefined>(new Date());
    const [realBtaStartDate, setRealBtaStartDate] = useState<Date | undefined>(new Date());
    const [availableBlocks, setAvailableBlocks] = useState<BTABlock[]>([]);
    const [selectedBtaTimeslot, setSelectedBtaTimeslot] = useState<SelectedBTATimeslot | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [calMonth, setCalMonth] = useState<Date | undefined>(new Date());
    const BTA_API_KEY = configService.getKnownPluginVariable(KnownPlugin.BookThatApp, 'BOOKTHATAPP_API_KEY');
    const canUseBTAPlugin = BTA_API_KEY !== null && BTA_API_KEY !== '';
    const [btaError, setBtaError] = useState<string | null>(null);

    const getResponsiveValueAsPx = (value: number) => {
        const menuHeight = getMenuHeight();
        const valueInPx = menuHeight * value;

        return valueInPx;
    };

    const getResponsiveValueAsStringPx = (value: number) => {
        return `${getResponsiveValueAsPx(value)}px`;
    };

    const getBtaBlocks = () => {
        setLoading(true);
        setTimeout(() => {
            if (!realBtaStartDate) {
                return;
            }

            const noTimezoneedDate = format(realBtaStartDate, 'yyyy-MM-dd');

            const startDateString = noTimezoneedDate;
            const endDateString = format(addDays(parse(noTimezoneedDate, 'yyyy-MM-dd', new Date()), 7), 'yyyy-MM-dd');

            const variantId = props.variantId?.replace('gid://shopify/ProductVariant/', '');
            const url = `https://api.bookthatapp.com/v1/blocks?external_id=${variantId}&start=${startDateString}&finish=${endDateString}&interval=60`;

            axios
                .get(url, {
                    headers: {
                        Authorization: 'Bearer ' + BTA_API_KEY, //the token is a variable which holds the token
                    },
                })
                .then(response => {
                    if (response.status !== 200) {
                        setAvailableBlocks([]);
                        console.error('Error getting BTA blocks');
                        setBtaError('BTA returned none 200 code: ' + response.status);
                        return;
                    } else {
                        const currentLocalTime = new Date();
                        // const currentTimeZoneOffset = currentLocalTime.getTimezoneOffset();
                        const blocks = response.data.blocks as BTABlock[];

                        if (Array.isArray(blocks) && blocks.length === 0) {
                            setAvailableBlocks([]);
                            return;
                        }

                        //now we need to check the timeslots inthe blocks to ensure the status is 1
                        const blocksWithAvailability = _.compact(
                            blocks.map(block => {
                                block.formattedDate = DateUtils.format(
                                    parse(block.date, 'yyyy-MM-dd', new Date()),
                                    'd LLL y',
                                );
                                block.formattedDateCheckout = DateUtils.format(
                                    parse(block.date, 'yyyy-MM-dd', new Date()),
                                    'dd/MM/yyyy',
                                );

                                let timeslots = block.timeslots.filter(
                                    timeslot => timeslot.status === 1 && timeslot.available > 0,
                                );

                                //now filter out timeslots where the start time is before the current time OR
                                // the start time has passed, but the finish time hasn't AND if the start time has passed and the finish hasn't, ensure there is at least 50% of the duration left
                                timeslots = timeslots.filter(timeslot => {
                                    const start = parseISO(timeslot.start);
                                    const finish = parseISO(timeslot.finish);

                                    const duration = finish.getTime() - start.getTime();
                                    const halfDuration = duration / 2;
                                    const halfDurationDate = start.getTime() + halfDuration;
                                    const halfDurationDateISO = parseISO(new Date(halfDurationDate).toISOString());

                                    return (
                                        start > currentLocalTime ||
                                        (start < currentLocalTime &&
                                            finish > currentLocalTime &&
                                            halfDurationDateISO > currentLocalTime)
                                    );
                                });

                                // timeslots = timeslots.filter(timeslot => {
                                //     const start = parseISO(timeslot.start);
                                //     const finish = parseISO(timeslot.finish);
                                //     return (
                                //         start > currentLocalTime ||
                                //         (start < currentLocalTime && finish > currentLocalTime)
                                //     );
                                // });

                                if (timeslots.length === 0) {
                                    return null;
                                }

                                //Now adjust the timeslots to be in the local timezone
                                timeslots.forEach(timeslot => {
                                    //adjust the start and finish times to be in the local timezone
                                    const start = parseISO(timeslot.start);
                                    const finish = parseISO(timeslot.finish);
                                    timeslot.formattedTime = getTextForTimeslot(start, finish);
                                });
                                return {
                                    ...block,
                                    timeslots,
                                };
                            }),
                        );

                        // const blocksWithAvailability = blocks.filter(block => block.timeslots > 0);
                        //Sort the blocks by date
                        blocksWithAvailability.sort((a, b) => {
                            const typedDateA = new Date(a.date);
                            const typedDateB = new Date(b.date);
                            if (typedDateA < typedDateB) {
                                return -1;
                            }
                            if (typedDateA > typedDateB) {
                                return 1;
                            }
                            return 0;
                        });
                        //Now get the first two blocks that exist (sorted by date)

                        const blocksToUse = _.take(blocksWithAvailability, 2);

                        setAvailableBlocks(blocksToUse);
                    }
                })
                .catch(error => {
                    console.error('Error getting BTA blocks', error);
                    setBtaError(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 500);
    };

    const handleConfirmCalendar = () => {
        if (btaStartDate) {
            setDatePickerModalOpen(false);
            setRealBtaStartDate(btaStartDate);
        }
    };

    const handleSelectedBTATimeslot = (block: BTABlock, timeslot: BTATimeslot) => {
        const value = `${block.date}-${timeslot.start}-${timeslot.finish}`;
        const selectedValue = `${selectedBtaTimeslot?.date}-${selectedBtaTimeslot?.start}-${selectedBtaTimeslot?.finish}`;

        if (value === selectedValue) {
            setSelectedBtaTimeslot(null);
            props.onChange([]);
            props.onValidate(false);
        } else {
            setSelectedBtaTimeslot({
                date: block.date,
                start: timeslot.start,
                finish: timeslot.finish,
            });
            // props.onChange({});
            props.onChange([
                { key: 'Date', value: block.formattedDateCheckout },
                { key: 'Time', value: DateUtils.format(new Date(timeslot.start), 'HH:mm') },
            ]);
            props.onLimitPurchaseQuantity(timeslot.available);
            props.onValidate(true);
        }
    };

    const getTextForTimeslot = (startDate: Date, finishDate: Date) => {
        //If the timesloe starts and finishes on the same date, return the text in the following format: 10:00 AM - 11:00 AM, otherwise return it as 10:00 AM - 4th Aug 2023 11:00 AM
        const startDateString = DateUtils.format(startDate, 'd LLL');
        const finishDateString = DateUtils.format(finishDate, 'd LLL');
        const startTimeString = DateUtils.format(startDate, 'HH:mm');
        const finishTimeString = DateUtils.format(finishDate, 'HH:mm');

        if (startDateString === finishDateString) {
            return `${startTimeString} - ${finishTimeString}`;
        } else {
            return `${startDateString} ${startTimeString} - ${finishDateString} ${finishTimeString}`;
        }
    };

    useEffect(() => {
        if (canUseBTAPlugin) {
            if (props.variantId && btaFields.length !== 0) {
                props.onValidate(false);
                setSelectedBtaTimeslot(null);
                getBtaBlocks();
            } else {
                setAvailableBlocks([]);
                props.onValidate(true);
            }
        } else {
            setAvailableBlocks([]);
            props.onValidate(true);
        }
    }, [props.variantId, realBtaStartDate]);

    if (!canUseBTAPlugin) {
        return null;
    }

    if (btaError !== null) {
        return (
            <div
                className={'BtaErrorBlock'}
                style={{
                    marginBottom: '1.5em',
                }}
            >
                <StyledText style={TextStyle.Subheading} size={TextSize.Small} translate={true} align={'center'}>
                    Book That App returned an error.
                    <br />
                    {btaError}
                </StyledText>
            </div>
        );
    }

    if (!props.variantId || !props.variantId.includes('gid://shopify/ProductVariant/')) {
        //BTA only supports shopify variants
        return null;
    }

    if (btaFields.length === 0) {
        //There is no BTA configuration for this product
        return null;
    }

    if (loading) {
        return <Loader variant={'dark'} />;
    }

    return (
        <>
            <section style={{ scrollMarginBottom: '150px' }} ref={ref}>
                {availableBlocks.map((block, index) => {
                    return (
                        <div
                            key={`availableBlocks-${index}`}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                                marginBottom: '1.5em',
                            }}
                        >
                            <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
                                {/*{index === 0 && (*/}
                                {/*    <StyledText style={TextStyle.Subheading} size={TextSize.Small} translate={true}>*/}
                                {/*        Date*/}
                                {/*    </StyledText>*/}
                                {/*)}*/}

                                <StyledText
                                    style={TextStyle.Subheading}
                                    size={TextSize.Small}
                                    translate={true}
                                    className={'DatePadding'}
                                >
                                    {block.formattedDate}
                                </StyledText>

                                {/*<Button size={ButtonSize.SM} className={'ProductVariantOptions__option'}>*/}
                                {/*    {format(new Date(block.date), 'do LLL y')}*/}
                                {/*</Button>*/}
                                {/*<ButtonSelectable*/}
                                {/*    size={ButtonSize.SM}*/}
                                {/*    className="ProductVariantOptions__option"*/}
                                {/*    key={block.date}*/}
                                {/*    forId={block.date}*/}
                                {/*    name={block.date}*/}
                                {/*    label={format(new Date(block.date), 'do LLL y')}*/}
                                {/*    type="checkbox"*/}
                                {/*    value={block.date}*/}
                                {/*    onSelected={() => {}}*/}
                                {/*    selected={false}*/}
                                {/*    preventChanges={true}*/}
                                {/*    // outlined*/}
                                {/*    // disabled={true}*/}
                                {/*/>*/}
                            </div>
                            <div
                                style={{ flex: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                            >
                                {/*{index === 0 && (*/}
                                {/*    <StyledText style={TextStyle.Subheading} size={TextSize.Small} translate={true}>*/}
                                {/*        Time*/}
                                {/*    </StyledText>*/}
                                {/*)}*/}
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {block.timeslots.map((timeslot, index) => {
                                        const value = `${block.date}-${timeslot.start}-${timeslot.finish}`;
                                        const selectedValue = `${selectedBtaTimeslot?.date}-${selectedBtaTimeslot?.start}-${selectedBtaTimeslot?.finish}`;
                                        const isSelected = value === selectedValue;
                                        return (
                                            <ButtonSelectable
                                                size={ButtonSize.SM}
                                                className="ProductVariantOptions__option"
                                                key={value}
                                                forId={value}
                                                name={value}
                                                label={timeslot.formattedTime}
                                                type="checkbox"
                                                value={value}
                                                onSelected={() => handleSelectedBTATimeslot(block, timeslot)}
                                                selected={isSelected}
                                                outlined
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}

                {availableBlocks.length === 0 && (
                    <div
                        className={'NoTimeslotWarningBox'}
                        style={{
                            marginBottom: '1.5em',
                        }}
                    >
                        <StyledText
                            style={TextStyle.Subheading}
                            size={TextSize.Small}
                            translate={true}
                            align={'center'}
                        >
                            {translationService.t('extensions.bookthatapp.no_timeslots')}
                            <br />
                            {translationService.t('extensions.bookthatapp.no_timeslots_two')}
                        </StyledText>
                    </div>
                )}

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                        marginTop: '3em',
                    }}
                >
                    {/*<div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>*/}
                    {/*    <StyledText*/}
                    {/*        style={TextStyle.Subheading}*/}
                    {/*        size={TextSize.Small}*/}
                    {/*        translate={true}*/}
                    {/*        className={'DatePadding'}*/}
                    {/*    >*/}
                    {/*        Select Another Date*/}
                    {/*    </StyledText>*/}

                    {/*    /!*<StyledText style={TextStyle.Subheading} size={TextSize.Small} translate={true}>*!/*/}
                    {/*    /!*    Select Another Date*!/*/}
                    {/*    /!*</StyledText>*!/*/}
                    {/*</div>*/}
                    <div style={{ flex: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Button
                            className={''}
                            size={ButtonSize.SM}
                            variant={ButtonVariant.GREY}
                            outline={ButtonOutlineVariant.PRIMARY}
                            // corners={ButtonCorners.SQUARED}
                            onClick={() => setDatePickerModalOpen(true)}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    padding: '0 1em 0 1em',
                                }}
                            >
                                {/*<div>{format(realBtaStartDate!, 'do LLL y')}</div>*/}
                                <div> {translationService.t('extensions.bookthatapp.select_another_date')}</div>
                                <div className={'ResetDateButton'}>
                                    <CalendarIcon />
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                <hr />
            </section>
            <SlidingModal
                fullWidth={true}
                origin={SlidingModalOrigin.BOTTOM}
                isOpen={datePickerModalOpen}
                onBackgroundClicked={() => setDatePickerModalOpen(false)}
                rounded={true}
            >
                <DayPicker
                    locale={DateUtils.getLocale()}
                    mode="single"
                    selected={btaStartDate}
                    onSelect={setBtaStartDate}
                    month={calMonth}
                    onMonthChange={setCalMonth}
                    showOutsideDays
                    fixedWeeks
                    fromDate={new Date()}
                    toDate={addYears(new Date(), 5)}
                    styles={{
                        root: {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            '--rdp-cell-size': getResponsiveValueAsStringPx(0.5),
                            '--rdp-caption-font-size': getResponsiveValueAsStringPx(0.225),
                            '--rdp-accent-color': 'black',
                            '--rdp-cell-width': `${(window.innerWidth - getResponsiveValueAsPx(0.125) * 7) / 7}px`,
                        },
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '1em',
                        gap: '1.5em',
                        padding: '1em',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        size={ButtonSize.MD}
                        variant={ButtonVariant.WHITE}
                        outline={ButtonOutlineVariant.CLEARALL}
                        translate={true}
                        onClick={() => {
                            setBtaStartDate(new Date());
                            setCalMonth(new Date());
                        }}
                    >
                        <div className={'ResetDateButton'}>{translationService.t('today')}</div>
                    </Button>
                    <Button
                        size={ButtonSize.MD}
                        variant={ButtonVariant.WHITE}
                        outline={ButtonOutlineVariant.CLEARALL}
                        onClick={() => setDatePickerModalOpen(false)}
                        translate={true}
                    >
                        {translationService.t('cancel')}
                    </Button>
                    <Button style={{ flex: 5 }} size={ButtonSize.MD} onClick={handleConfirmCalendar} translate={true}>
                        {translationService.t('confirm')}
                    </Button>
                </div>
            </SlidingModal>
        </>
    );
});

export default ProductBookThatAppSection;
