import React, { useEffect, useState } from 'react';
import { Slider } from '../../../../components/shared/inputs/Slider/Slider';
import { useInjection } from '../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../dependancyInjection/DependencyType';
import CurrencyService from '../../../CurrencyService/CurrencyService';
import { FilterInputWrapper } from './wrappers/FilterInputWrapper';
import _ from 'lodash';
import { FilterSelection, ProductFilteringService } from '../../ProductFilteringService';
import './RangeFilterInput.scss';
import useStateRef from 'react-usestateref';
import { CloudshelfEngineFilter } from '../../../ConfigurationService/types/filters/CloudshelfEngineFilter';
import { FunctionalComponentWithChildren } from '../../../../FCWithChildren';

export interface RangeFilterItemProps {
    definition: CloudshelfEngineFilter;
    label: string;
    categoryId: string;
    isInternalAll: boolean;
}

export const RangeFilterInput: FunctionalComponentWithChildren<RangeFilterItemProps> = React.memo(props => {
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const definition = props.definition;

    const matchingValues = definition.attributeValues;

    const minValue = _.minBy(matchingValues, mv => parseFloat(mv.value))?.value ?? '0';
    const maxValue = _.maxBy(matchingValues, mv => parseFloat(mv.value))?.value ?? '1';

    const [thisFilterSelection, setThisFilterSelection, thisFilterSelectionRef] = useStateRef(
        _.find(
            filteringService.getCurrentSelection(),
            selection => selection.name === definition.ecommProviderFieldName,
        ),
    );

    const [valueLabel, setValueLabel] = useState<string>();
    const currentMin = parseFloat(thisFilterSelection?.values[0] ?? minValue);
    const currentMax = parseFloat(thisFilterSelection?.values[1] ?? maxValue);

    useEffect(() => {
        const filterViewStateObserver = filteringService.observeFilterViewSelectionState();

        const filterViewStateObserverSubscription = filterViewStateObserver.subscribe(
            async (selection: FilterSelection[]) => {
                setThisFilterSelection(
                    _.find(selection, selection => selection.name === definition.ecommProviderFieldName),
                );
                generateLabel(
                    parseFloat(thisFilterSelectionRef.current?.values[0] ?? minValue),
                    parseFloat(thisFilterSelectionRef.current?.values[1] ?? maxValue),
                );
            },
        );

        return () => filterViewStateObserverSubscription.unsubscribe();
    }, []);

    const generateLabel = (sliderMin: number, sliderMax: number) => {
        setValueLabel(`${CurrencyService.formatRounded(sliderMin)} - ${CurrencyService.format(sliderMax)}`);
    };

    const handleSliderChange = ([sliderMin, sliderMax]: readonly number[]) => {
        if (isNaN(sliderMin) || isNaN(sliderMax)) {
            return;
        }

        filteringService.setRangeValue(
            definition.id,
            definition.ecommProviderFieldName,
            definition.type,
            sliderMin,
            sliderMax,
        );
        generateLabel(sliderMin, sliderMax);
    };

    const handleSliderUpdate = ([sliderMin, sliderMax]: readonly number[]) => {
        if (isNaN(sliderMin) || isNaN(sliderMax)) {
            return;
        }
        generateLabel(sliderMin, sliderMax);
    };

    useEffect(() => {
        generateLabel(currentMin, currentMax);
    }, [props.definition, currentMax, currentMin]);

    // Math.floor and MathCeil are in place as the filter breaks if products end in 96/97/98/99 parts of a
    // currency
    if (currentMin === currentMax) {
        return null;
    }

    // If currentMax - currentMin < 5, that is the number of ticks, otherwise ticks = 5
    //const ticks = currentMax - currentMin < 5 ? currentMax - currentMin : 5;
    const ticks = 5;
    return (
        <FilterInputWrapper label={props.label} subLabel={valueLabel} expandedByDefault={true} flipId={definition.id}>
            <p className="RangeFilterInput__label">{valueLabel}</p>
            <Slider
                domain={[Math.floor(parseFloat(minValue)), Math.ceil(parseFloat(maxValue))]}
                className="RangeFilterInput__slider"
                onUpdate={handleSliderUpdate}
                onChange={handleSliderChange}
                values={[Math.floor(currentMin), Math.ceil(currentMax)]}
                ticks={ticks}
            />
        </FilterInputWrapper>
    );
});
