import React, { FC, memo } from 'react';
import DisplayOnlyQR from '../../apps/DisplayApp/components/DisplayOnlyQR/DisplayOnlyQR';
import { CSSTransition } from 'react-transition-group';
import TimeBar from '../../apps/DisplayApp/components/TimeBar/TimeBar';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { DisplayOnlyTrackedHandleType } from '../../../services/TrackedURLService/DisplayOnlyTrackedHandleType.enum';
import { Banner } from '../../../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import { BannerBackgroundType, BannerLinkType } from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { RoutesHelperService } from '../../../services/RoutesService/RoutesHelperService';
import { EventsService } from '../../../services/EventsService/EventsService';
import { getSizedImageURL } from '../../../utils/ImageURL.Util';
import { Textfit } from 'react-textfit';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export interface BannerPaneProps {
    banner: Banner;
    active: boolean;
    onTapped?: () => void;
    className?: string;
}

const BannerPane: FunctionalComponentWithChildren<BannerPaneProps> = memo(props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    let qrHandleType: DisplayOnlyTrackedHandleType | undefined;
    let qrHandleLink: string | undefined;
    let imageBackgroundSource: string | undefined;
    const timerBarLengthInMilliseconds: number | undefined =
        (configService.config()?.bannerDisplayRules.display.duration ?? 1) * 1000;

    if (props.banner.linkType === BannerLinkType.Url) {
        if (props.banner.linkType === BannerLinkType.Url) {
            qrHandleType = DisplayOnlyTrackedHandleType.BANNER_LINK;
            qrHandleLink = props.banner.linkURL;
        } else if (props.banner.linkType === BannerLinkType.ProductGroup) {
            qrHandleType = DisplayOnlyTrackedHandleType.CATEGORY;
            qrHandleLink = props.banner.linkProductGroup;
        } else if (props.banner.linkType === BannerLinkType.Product) {
            qrHandleType = DisplayOnlyTrackedHandleType.PRODUCT;
            qrHandleLink = props.banner.linkProduct;
        }
    }

    const isLandscape = window.innerHeight < window.innerWidth;
    if (props.banner.backgroundType === BannerBackgroundType.Image) {
        if (isLandscape) {
            imageBackgroundSource = props.banner.backgroundImageHorizontal ?? props.banner.backgroundImageVertical;
        } else {
            imageBackgroundSource = props.banner.backgroundImageVertical ?? props.banner.backgroundImageHorizontal;
        }
    }

    const windowHeight = window.innerHeight;
    const minFontSize = windowHeight / 20;
    const maxFontSize = windowHeight / 5;

    return (
        <div onClick={props.onTapped}>
            <CSSTransition
                in={props.active}
                timeout={500}
                appear
                unmountOnExit
                mountOnEnter
                classNames={`${props.className} BannerPane BannerPane`}
            >
                <div
                    className="BannerPane__wrapper"
                    style={{
                        backgroundColor:
                            props.banner.backgroundType === BannerBackgroundType.SolidColour
                                ? props.banner.backgroundColour
                                : 'inherit',
                    }}
                >
                    {(configService.config()?.banners ?? []).length > 1 && (
                        <TimeBar
                            numberOfBlocks={1}
                            millisecondsPerBlock={timerBarLengthInMilliseconds}
                            activeBlock={0}
                            forceFullWidth
                        />
                    )}

                    {props.banner.backgroundType === BannerBackgroundType.Image && (
                        <>
                            <img
                                src={getSizedImageURL(imageBackgroundSource)}
                                alt="Banner Image"
                                className="BannerPane__background__blur"
                            />
                            <img
                                src={getSizedImageURL(imageBackgroundSource)}
                                alt="Banner Image"
                                className="BannerPane__background"
                            />
                        </>
                    )}
                    <div className="BannerPane__content">
                        {!qrHandleType || !qrHandleLink ? null : (
                            <DisplayOnlyQR type={qrHandleType} handle={qrHandleLink} reducedMargin={true} />
                        )}
                        <div className="BannerPane__content__title">
                            <Textfit min={minFontSize} max={maxFontSize}>
                                <p>{props.banner.bannerText[0]}</p>
                            </Textfit>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
});

export default BannerPane;
