export interface ProductVariantOptionParams {
    name: string;
    value: string;
}

export default class ProductVariantOption {
    private readonly _name: string;

    private readonly _value: string;

    constructor(params: ProductVariantOptionParams) {
        this._name = params.name;
        this._value = params.value;
    }

    get name(): string {
        return this._name;
    }

    get value(): string {
        return this._value;
    }
}
