import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { BasketList } from '../Basket/BasketList';
import { PanelHeader } from '../PanelHeader/PanelHeader';
import { Basket, BasketItem } from '../../../../../services/BasketService/Basket.type';
import { LogUtil } from '../../../../../utils/Logging.Util';
import { CheckoutTotalOverlay } from './CheckoutTotalOverlay';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { BasketService } from '../../../../../services/BasketService/BasketService';
import { LocalProduct, LocalProductVariant } from '../../../../../services/ProductServices/LocalProduct';
import { KeyValuePair } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { ContinuePurchaseFlowCallback } from '../../../../../hooks/contexts/Purchase/Purchase';
import { VariantSelectorModal } from '../VariantSelectorModal/VariantSelectorModal';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export interface CheckoutViewProps {
    isOpen: boolean;
    onClose: () => void;
    setLoading: (loading: boolean) => void;
    loading: boolean;
    // onPurchase: PurchaseCallback;
    onContinue: ContinuePurchaseFlowCallback;
}

export const CheckoutView: FunctionalComponentWithChildren<CheckoutViewProps> = ({ onClose, setLoading, onContinue, loading, isOpen }) => {
    const { t } = useTranslation();
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const [checkoutBasket, setCheckoutBasket] = useState<Basket>();
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    const productCustomiserPriceModifierProduct = configService.productCustomiserPriceModifierProduct;
    const productCustomiserPriceModifierVariant = configService.productCustomiserPriceModifierVariant;
    const [variantSelectorLocalProduct, setVariantSelectorLocalProduct] = useState<LocalProduct | undefined>(undefined);
    const [variantSelectorCurrentVariant, setVariantSelectorCurrentVariant] = useState<LocalProductVariant | undefined>(
        undefined,
    );
    const [variantSelectorBasketItem, setVariantSelectorBasketItem] = useState<BasketItem | undefined>(undefined);

    useEffect(() => {
        const subscriber = basketService.observeBasket().subscribe(basket => {
            setCheckoutBasket(basket);
        });
        return () => {
            subscriber.unsubscribe();
        };
    }, [basketService]);

    const handleQuantityChange = async (
        product: LocalProduct,
        variant: LocalProductVariant,
        customAttributes: KeyValuePair[],
        newQuantity: number,
        oldQuantity: number,
    ) => {
        await basketService.setItemQuantity(product, variant, newQuantity, customAttributes);

        if (productCustomiserPriceModifierVariant && productCustomiserPriceModifierProduct) {
            const qty = await basketService.getItemQuantity(productCustomiserPriceModifierVariant.id, customAttributes);
            if (qty > 0) {
                const qtyPerItem = qty / oldQuantity;
                const newQty = qtyPerItem * newQuantity;
                await basketService.setItemQuantity(
                    productCustomiserPriceModifierProduct,
                    productCustomiserPriceModifierVariant,
                    newQty,
                    customAttributes,
                );
            }
        }

        setCheckoutBasket(basketService.basket);
        LogUtil.LogObject(['handleQuantityChange', basketService.basket]);
    };

    const handleBasketItemRemoved = async (item: LocalProductVariant, customAttributes: KeyValuePair[]) => {
        await basketService.removeItem(item, customAttributes);
        if (productCustomiserPriceModifierVariant) {
            await basketService.removeItem(productCustomiserPriceModifierVariant, customAttributes);
        }
    };

    useEffect(() => {
        if (checkoutBasket?.lineItems.length === 0) {
            onClose();
        }
    }, [checkoutBasket, onClose]);

    return (
        <>
            <div className="CheckoutView">
                <PanelHeader
                    className="CheckoutView__header"
                    onCloseButtonClicked={onClose}
                    title={t('checkout_view.title')}
                    bottomBorder
                    translate
                />
                {checkoutBasket && (
                    <BasketList
                        isOpen={isOpen}
                        basket={checkoutBasket}
                        className="CheckoutView__basketList"
                        onQuantityChanged={handleQuantityChange}
                        onBasketItemRemoved={handleBasketItemRemoved}
                        setLoading={setLoading}
                        onVariantSelectorOpen={(product, variant, basketItem) => {
                            setVariantSelectorLocalProduct(product);
                            setVariantSelectorCurrentVariant(variant);
                            setVariantSelectorBasketItem(basketItem);
                        }}
                    />
                )}
                <CheckoutTotalOverlay onContinue={onContinue} loading={loading} />
            </div>
            <VariantSelectorModal
                onClose={() => {
                    setVariantSelectorLocalProduct(undefined);
                    setVariantSelectorCurrentVariant(undefined);
                    setVariantSelectorBasketItem(undefined);
                }}
                forProduct={variantSelectorLocalProduct}
                currentVariant={variantSelectorCurrentVariant}
                basketItem={variantSelectorBasketItem}
            />
        </>
    );
};
