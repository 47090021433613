import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import Button from '../../../../shared/inputs/Button/Button';
import { CheckoutService } from '../../../../../services/CheckoutService/CheckoutService';
import { Checkout, CheckoutStyle } from '../../../../../services/CheckoutService/Checkout.type';
import { SentryUtil } from '../../../../../utils/Sentry.Util';
import { SessionManagementService } from '../../../../../services/SessionManagementService/SessionManagementService';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import {
    ContinuePurchaseFlowCallback,
    PurchaseStep,
    usePurchase,
} from '../../../../../hooks/contexts/Purchase/Purchase';
import { CloudshelfBridge, RequestPaymentPayload } from '../../../../../utils/CloudshelfBridge.Utils';
import { ulid } from 'ulidx';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import { BasketService } from '../../../../../services/BasketService/BasketService';
import CurrencyService from '../../../../../services/CurrencyService/CurrencyService';
import { CheckoutAcquisitionOption } from '../../../../../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import { AcquisitionOptionUtil } from '../../../../../utils/AcquisitionOption.Util';

export interface CheckoutTotalOverlayProps {
    onContinue: ContinuePurchaseFlowCallback;
    loading: boolean;
}

export const CheckoutTotalOverlay: FunctionalComponentWithChildren<CheckoutTotalOverlayProps> = ({
    onContinue,
    loading,
}) => {
    const sessionService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    const { t } = useTranslation();
    const checkoutService = useInjection<CheckoutService>(DependencyType.CheckoutService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const [render, setRender] = useState(0);
    const [cloudshelfPlayerPaymentInProgress, setCloudshelfPlayerPaymentInProgress] = useState(false);
    const availableCheckoutAcquisitionOptions = basketService.getAvailableCheckoutAcquisitionOptions();
    const basketTotal = checkoutService.totalPriceAsNumber;
    const purchaseContext = usePurchase();

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined = undefined;

        if (checkoutService.checkoutStyle === CheckoutStyle.ON_DEVICE) {
            if (cloudshelfPlayerPaymentInProgress) {
                interval = setInterval(() => {
                    const status = CloudshelfBridge.getCurrentPaymentStatus();
                    if (status !== 'payment_pending') {
                        clearInterval(interval!);
                        setCloudshelfPlayerPaymentInProgress(false);

                        if (status === 'idle') {
                            //do nothing
                        } else if (status === 'payment_success') {
                            setTimeout(() => {
                                purchaseContext.continue(PurchaseStep.PAYMENT_SUCCESS);
                            }, 250);
                        }
                    }
                }, 500);
            }
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [cloudshelfPlayerPaymentInProgress]);

    useEffect(() => {
        const sub = checkoutService.observeCheckout().subscribe((ckout: Checkout | undefined) => {
            //force a re-render
            setRender(old => old + 1);
        });

        return () => {
            sub.unsubscribe();
        };
    }, [checkoutService, cloudshelfPlayerPaymentInProgress]);

    const totalPrice = checkoutService.totalPrice;
    const subTotalPrice = checkoutService.subTotalPrice;
    const discount = checkoutService.discount;
    const tax = checkoutService.estimatedTax;
    const waitingOnPlatform = checkoutService.loading;

    const handleCheckout = useCallback(
        (option: CheckoutAcquisitionOption) => {
            onContinue(PurchaseStep.PAYMENT, option);
        },
        [onContinue],
    );

    if (waitingOnPlatform) {
        return null;
    }

    const handleCheckoutOnDevice = () => {
        if (CloudshelfBridge.isAvailable()) {
            setCloudshelfPlayerPaymentInProgress(true);
            CloudshelfBridge.setCurrentPaymentStatus('payment_pending');

            const price = checkoutService.totalPriceAsNumber * 100; //we need it in pence/cents
            const requestPaymentPayload: RequestPaymentPayload = {
                money: price,
                uniqueTransactionId: ulid(),
            };
            SentryUtil.StartInfoTransaction(
                'CloudshelfPlayer:RequestPayment',
                JSON.stringify(requestPaymentPayload),
            ).newTransaction.finish();

            CloudshelfBridge.requestPayment(requestPaymentPayload);
        } else {
            console.log('CloudshelfBridge not found');
        }
    };

    return (
        <div className="CheckoutTotalOverlay">
            <div className={'CheckoutTotalOverlay__summary'}>
                {!discount && subTotalPrice !== totalPrice && (
                    <div className="CheckoutTotalOverlay__totalContainer">
                        <StyledText
                            className="CheckoutTotalOverlay__totalLabel"
                            style={TextStyle.Subheading}
                            size={TextSize.Medium}
                            translate
                        >
                            {t('checkout_view.summary')}
                        </StyledText>

                        <StyledText
                            className="CheckoutTotalOverlay__totalLabel"
                            style={TextStyle.Subheading}
                            size={TextSize.Medium}
                        >
                            {subTotalPrice}
                        </StyledText>
                    </div>
                )}
                {discount && (
                    <>
                        <div className="CheckoutTotalOverlay__totalContainer">
                            <StyledText
                                className="CheckoutTotalOverlay__totalLabel"
                                style={TextStyle.Subheading}
                                size={TextSize.Medium}
                                translate
                            >
                                {t('checkout_view.summary')}
                            </StyledText>

                            <StyledText
                                className="CheckoutTotalOverlay__subTotalPrice"
                                style={TextStyle.Subheading}
                                size={TextSize.Medium}
                            >
                                {subTotalPrice}
                            </StyledText>
                        </div>
                        {discount && (
                            <div className="CheckoutTotalOverlay__totalContainer">
                                <StyledText
                                    className="CheckoutTotalOverlay__totalLabel"
                                    style={TextStyle.Body}
                                    size={TextSize.Small}
                                    translate
                                >
                                    <span>{t('checkout_view.discount')}:</span>
                                </StyledText>
                                <StyledText
                                    className="CheckoutTotalOverlay__discount"
                                    style={TextStyle.Subheading}
                                    size={TextSize.Medium}
                                >
                                    <span>{discount}</span>
                                </StyledText>
                            </div>
                        )}
                    </>
                )}
                {/*<br />*/}
                {tax && (
                    <div className="CheckoutTotalOverlay__totalContainer">
                        <StyledText
                            className="CheckoutTotalOverlay__totalLabel"
                            style={TextStyle.Body}
                            size={TextSize.Small}
                            translate
                        >
                            <span>{t('checkout_view.estimated_tax')}:</span>
                        </StyledText>
                        <StyledText
                            className="CheckoutTotalOverlay__totalLabel"
                            style={TextStyle.Subheading}
                            size={TextSize.Medium}
                        >
                            <span>{tax}</span>
                        </StyledText>
                    </div>
                )}
                <div className="CheckoutTotalOverlay__totalContainer">
                    <StyledText
                        className="CheckoutTotalOverlay__totalLabel"
                        style={TextStyle.Body}
                        size={TextSize.Small}
                        translate
                    >
                        {t('checkout_view.totalExlDelivery')}
                    </StyledText>

                    <StyledText
                        className="CheckoutTotalOverlay__totalLabel"
                        style={TextStyle.Subheading}
                        size={TextSize.Medium}
                        translate
                    >
                        {totalPrice}
                    </StyledText>
                </div>
            </div>
            <div className="CheckoutTotalOverlay__checkoutButtons">
                {availableCheckoutAcquisitionOptions.map((option, index) => {
                    const isLastInList = index === availableCheckoutAcquisitionOptions.length - 1;
                    let displayText = option.displayName;

                    const acquisitionCost = AcquisitionOptionUtil.getAcquisitionCost(option, basketTotal);

                    if (acquisitionCost > 0) {
                        displayText = `${option.displayName} (${CurrencyService.format(acquisitionCost)})`;
                    }

                    return (
                        <Button
                            className={
                                isLastInList
                                    ? 'CheckoutTotalOverlay__checkoutButtons__button__prefered'
                                    : 'CheckoutTotalOverlay__checkoutButtons__button'
                            }
                            onClick={() => handleCheckout(option)}
                            loading={loading}
                            showLoader
                            translate
                            useContrastColor={isLastInList}
                            key={index}
                        >
                            {displayText}
                        </Button>
                    );
                })}
            </div>
            {/* {checkoutService.checkoutStyle === CheckoutStyle.QR && (
                <Button
                    className="CheckoutTotalOverlay__checkoutButton"
                    onClick={handleCheckout}
                    loading={loading}
                    showLoader
                    translate
                    useContrastColor
                >
                    {t('checkout_view.phone_checkout')}
                </Button>
            )}
            {checkoutService.checkoutStyle === CheckoutStyle.ON_DEVICE && (
                <Button
                    className="CheckoutTotalOverlay__checkoutButton"
                    onClick={handleCheckoutOnDevice}
                    loading={loading || cloudshelfPlayerPaymentInProgress}
                    showLoader
                    translate
                    useContrastColor
                >
                    {t('checkout_view.finishAndPay')}
                </Button>
            )} */}
        </div>
    );
};
