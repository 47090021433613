import React, { FC, useEffect, useState } from 'react';
import StyledText, { TextSize, TextStyle } from '../../../../../shared/StyledText/StyledText';
import EmptyBasketOutline from '../../../../../icons/empty_basket_outline';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import { BasketService } from '../../../../../../services/BasketService/BasketService';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import { Basket, BasketItem } from '../../../../../../services/BasketService/Basket.type';
import Button, { ButtonSize, ButtonVariant } from '../../../../../shared/inputs/Button/Button';
import { Base64 } from 'js-base64';
import { ConfigurationService } from '../../../../../../services/ConfigurationService/ConfigurationService';
import BasketPaneItem from './BasketPaneItem/BasketPaneItem';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import _ from 'lodash';
import { PurchaseStep, usePurchase } from '../../../../../../hooks/contexts/Purchase/Purchase';
import CurrencyService from '../../../../../../services/CurrencyService/CurrencyService';
import { useTranslation } from 'react-i18next';
import { VariantSelectorModal } from '../../VariantSelectorModal/VariantSelectorModal';
import { LocalProduct, LocalProductVariant } from '../../../../../../services/ProductServices/LocalProduct';
import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';

const BasketPane: FunctionalComponentWithChildren = props => {
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const [checkoutBasket, setCheckoutBasket] = useState<Basket>();
    const [isBasketEmpty, setIsBasketEmpty] = useState<boolean>(false);
    const productCustomiserPriceModifierVariant = configService.productCustomiserPriceModifierVariant;
    const [parent, enableAnimations] = useAutoAnimate(/* optional config */);
    const purchaseContext = usePurchase();
    const [isOnUpsell, setIsOnUpsell] = useState(false);
    const [variantSelectorLocalProduct, setVariantSelectorLocalProduct] = useState<LocalProduct | undefined>(undefined);
    const [variantSelectorCurrentVariant, setVariantSelectorCurrentVariant] = useState<LocalProductVariant | undefined>(
        undefined,
    );
    const [variantSelectorBasketItem, setVariantSelectorBasketItem] = useState<BasketItem | undefined>(undefined);

    const { t } = useTranslation();

    useEffect(() => {
        const subscriber = basketService.observeBasket().subscribe(basket => {
            setCheckoutBasket(basket);
            if (basket === undefined) {
                setIsBasketEmpty(true);
            } else {
                setIsBasketEmpty(basket.lineItems.length === 0);
            }
        });
        return () => {
            subscriber.unsubscribe();
        };
    }, [basketService]);

    useEffect(() => {
        setIsOnUpsell(purchaseContext.currentStep === PurchaseStep.UPSELL_IF_APPLICABLE);
    }, [purchaseContext.currentStep]);

    return (
        <>
            <div className={'BasketPane'}>
                <div className={'BasketPane__wrapper'}>
                    <div className={'BasketPane__header'}>
                        <StyledText style={TextStyle.Subheading} size={TextSize.Medium} translate>
                            {t('basket.basket')}
                        </StyledText>
                    </div>
                    <div className={'BasketPane__content'}>
                        {isBasketEmpty ? (
                            <div className={'BasketPane__empty'}>
                                <EmptyBasketOutline />
                                <StyledText
                                    className={'BasketPane__empty__text'}
                                    style={TextStyle.Body}
                                    size={TextSize.Medium}
                                    translate
                                    align="center"
                                >
                                    {t('basket.empty')}
                                </StyledText>
                            </div>
                        ) : (
                            <div className={'BasketPane__content__items'} ref={parent}>
                                {checkoutBasket?.lineItems.map(item => (
                                    <BasketPaneItem
                                        key={`basket-pane-item-${item.variant.id}-${Base64.encode(
                                            JSON.stringify(item.attributes),
                                        )}`}
                                        item={item}
                                        onBasketItemRemoved={() => {}}
                                        productCustomiserVariant={productCustomiserPriceModifierVariant}
                                        onVariantSelectorOpen={(product, variant, basketItem) => {
                                            purchaseContext.continue(PurchaseStep.NONE);
                                            setVariantSelectorLocalProduct(product);
                                            setVariantSelectorCurrentVariant(variant);
                                            setVariantSelectorBasketItem(basketItem);
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {!isBasketEmpty && (
                    <div className={'BasketPane__footer'}>
                        {checkoutBasket !== undefined && !isBasketEmpty && (
                            <div className={'BasketPane__footer__total'}>
                                <StyledText size={TextSize.Medium} style={TextStyle.Heading}>
                                    {t('checkout_view.total')}
                                    {': '}
                                    {CurrencyService.format(
                                        _.sumBy(
                                            checkoutBasket.lineItems ?? [],
                                            item => item.variant.price * item.quantity,
                                        ),
                                    )}
                                </StyledText>
                                <StyledText size={TextSize.Small} style={TextStyle.Body}>
                                    {t('basket.beforeTaxAndShipping')}
                                </StyledText>
                            </div>
                        )}
                        {!isBasketEmpty && (
                            <Button
                                translate
                                size={ButtonSize.MD}
                                variant={ButtonVariant.PRIMARY}
                                className={`BasketPane__footer__purchase ${
                                    isOnUpsell && 'BasketPane__footer__purchase__finish'
                                }`}
                                onClick={() => {
                                    if (isOnUpsell) {
                                        purchaseContext.continue(PurchaseStep.BASKET);
                                    } else {
                                        purchaseContext.continue(PurchaseStep.UPSELL_IF_APPLICABLE);
                                    }
                                }}
                            >
                                {!isOnUpsell && t('basket.checkout')}
                                {isOnUpsell && t('continue')}
                            </Button>
                        )}
                    </div>
                )}
            </div>
            <VariantSelectorModal
                onClose={() => {
                    setVariantSelectorLocalProduct(undefined);
                    setVariantSelectorCurrentVariant(undefined);
                    setVariantSelectorBasketItem(undefined);
                }}
                forProduct={variantSelectorLocalProduct}
                currentVariant={variantSelectorCurrentVariant}
                basketItem={variantSelectorBasketItem}
            />
        </>
    );
};

export default BasketPane;
