import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, {
    ButtonOutlineVariant,
    ButtonSize,
    ButtonVariant,
} from '../../../components/shared/inputs/Button/Button';
import { PanelHeader } from '../../../components/apps/InteractiveApp/components/PanelHeader/PanelHeader';
import Loader from '../../../components/shared/Loader/Loader';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { DiscreteFilterInput } from './inputs/DiscreteFilterInput';
import { RangeFilterInput } from './inputs/RangeFilterInput';
import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { FilterSelection, ProductFilteringService } from '../ProductFilteringService';
import { useHistory } from 'react-router-dom';
import { Flipper } from 'react-flip-toolkit';
import { Base64 } from 'js-base64';
import useStateRef from 'react-usestateref';
import { EventsService } from '../../EventsService/EventsService';
import {
    SlidingModal,
    SlidingModalOrigin,
} from '../../../components/apps/InteractiveApp/components/SlidingModal/SlidingModal';
import { RoutesHelperService } from '../../RoutesService/RoutesHelperService';
import { CloudshelfEngineFilter } from '../../ConfigurationService/types/filters/CloudshelfEngineFilter';
import { FilterValueType } from '../../ConfigurationService/types/filters/FilterValueType';
import { CategoryService } from '../../CategoryService/CategoryService';
import { Category } from '../../CategoryService/entities/Category';
import { SessionEvent, SessionEventType } from '../../SessionManagementService/SessionEvent';
import { SessionManagementService } from '../../SessionManagementService/SessionManagementService';
import { MenuService } from '../../MenuService/MenuService';
import { FilterType } from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import './FiltersView.scss';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

let icon: React.ReactElement | null;

const UNKNOWN_COUNT = -1;
const UPDATE_EVERY_IN_MS = 250;

export type FiltersSubmittedCallback = (filters: FilterSelection[]) => void;

export const FiltersView: FunctionalComponentWithChildren = React.memo(() => {
    const { t } = useTranslation();
    const history = useHistory();
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const categoriesService = useInjection<CategoryService>(DependencyType.CategoryService);
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const [category, setCategory] = useState<Category>();
    const [isOpen, setIsOpen, isOpenRef] = useStateRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const [matchingProductsCount, setMatchingProductsCount] = useState(UNKNOWN_COUNT);
    const [selectedFilters, setSelectedFilters] = useState<FilterSelection[]>(filteringService.getCurrentSelection());
    const [filters, setFilters] = useState<CloudshelfEngineFilter[]>([]);
    const filterSelectedChangedRef = useRef(false);

    useEffect(() => {
        const sessionObserver = sessionManagementService.observe();

        const sessionObserverSubscription = sessionObserver.subscribe((event: SessionEvent) => {
            if (event.type === SessionEventType.Ended) {
                if (isOpenRef.current) {
                    setIsOpen(false);
                }
            }
        });

        return () => sessionObserverSubscription.unsubscribe();
    }, []);

    useEffect(() => {
        const filterViewStateObserver = filteringService.observeFilterViewSelectionState();

        const filterViewStateObserverSubscription = filterViewStateObserver.subscribe(async filterSelection => {
            setSelectedFilters(filterSelection);
            filterSelectedChangedRef.current = true;
        });

        return () => filterViewStateObserverSubscription.unsubscribe();
    }, [category]);

    useEffect(() => {
        const subscriber = menuService.observeFilterTapped().subscribe(() => {
            setIsOpen(true);
        });
        return () => {
            subscriber?.unsubscribe();
        };
    }, [menuService]);

    useEffect(() => {
        eventsService.setIsFilterPanelOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        const openCategoryObservable = eventsService.observeOpenCategory();
        const subscriber = openCategoryObservable.subscribe(category => {
            if (category === undefined) {
                //We are on the attract screen
                const internalAll = categoriesService.getByHandle('INTERNAL_ALL');
                setCategory(internalAll);
            } else {
                setCategory(category);
            }
        });

        // This will run only once, if we are on the attract screen, category will be undefined
        // and we want to pick the internal all category if we have it
        if (category === undefined) {
            const internalAll = categoriesService.getByHandle('INTERNAL_ALL');
            setCategory(internalAll);
        }

        return () => {
            subscriber?.unsubscribe();
        };
    }, []);

    const updateMatchingCount = async (category: Category | undefined, filters: FilterSelection[]) => {
        try {
            const count = await filteringService.countMatchingProducts(
                'filters view -> updateMatchingCount',
                category,
                filters,
                true,
            );
            setMatchingProductsCount(count);
        } catch (err) {
            setMatchingProductsCount(UNKNOWN_COUNT);
            Sentry.captureException(err, {
                extra: {
                    operationName: 'updateMatchingCount',
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    const refetchProducts = async () => {
        if (filterSelectedChangedRef.current) {
            if (selectedFilters.length === 0) {
                setMatchingProductsCount(UNKNOWN_COUNT);
                return;
            }

            setIsLoading(true);
            await updateMatchingCount(category, selectedFilters);
            const visible = filteringService.visibleFilterOptions();
            setFilters(visible);
        }
    };

    const debouncedRefetch = _.debounce(refetchProducts, UPDATE_EVERY_IN_MS);

    useEffect(() => {
        debouncedRefetch();
    }, [selectedFilters]);

    const handleFiltersSubmission = () => {
        if (category?.isInternalAllCategory) {
            const categoryProductsRoute = RoutesHelperService.toCategoryProducts(category);
            history.push(categoryProductsRoute);
        }
        filteringService.commitSelection();
        setIsOpen(false);
    };

    const handleClearAll = () => {
        filteringService.clearSelection(true);
    };

    const handleExitWithoutSaving = async () => {
        filteringService.refreshViewSelection();
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            void updateMatchingCount(eventsService.openCategory, filteringService.getCurrentViewSelection());
        }
    }, [isOpen]);

    const filterItems = useMemo(() => {
        return _.map(
            _.sortBy(filters, filter => filter.priority),
            filter => {
                const catId = category?.internalId ?? '';
                let isInternalAll = category?.isInternalAllCategory ?? false;

                if (catId === '') {
                    isInternalAll = true;
                }

                if (filter.valueType === FilterValueType.DISCRETE) {
                    return (
                        <DiscreteFilterInput
                            key={`filter-list-${filter.id}`}
                            definition={filter}
                            label={filter.displayName}
                            icon={icon}
                            categoryId={''}
                            forceSingleValue={filter.type === FilterType.SortOrder}
                        />
                    );
                }

                if (filter.valueType === FilterValueType.RANGE) {
                    return (
                        <RangeFilterInput
                            key={`filter-list-${filter.id}`}
                            definition={filter}
                            label={filter.displayName}
                            categoryId={catId}
                            isInternalAll={isInternalAll}
                        />
                    );
                }
                return null;
            },
        );
    }, [filters, category, window.location.pathname]);

    let showButtonLabel: string;
    if (matchingProductsCount === UNKNOWN_COUNT) {
        showButtonLabel = t('filters_view.show_all');
    } else if (matchingProductsCount === 0) {
        showButtonLabel = t('filters_view.no_products');
    } else {
        showButtonLabel = t('filters_view.show', {
            productsCount: matchingProductsCount < 100 ? `${matchingProductsCount}` : '99+',
        });
    }

    return (
        <SlidingModal
            onBackgroundClicked={handleExitWithoutSaving}
            isOpen={isOpen}
            origin={SlidingModalOrigin.RIGHT}
            rounded
            fullHeight
        >
            <div className="FiltersView">
                <PanelHeader
                    className="FiltersView__header"
                    onCloseButtonClicked={handleExitWithoutSaving}
                    bottomBorder
                    title={t('filters_view.filterAndSort')}
                    leftAlignTitle
                />
                <div className="FiltersView__list">
                    <Flipper
                        element={'ul'}
                        flipKey={Base64.encode(
                            _.map(filters, f => _.map(f.attributeValues, av => av.value).join(':')).join(':'),
                        )}
                    >
                        {filterItems}
                    </Flipper>
                </div>
                <div className="FiltersView__footer">
                    <Button
                        size={ButtonSize.LG}
                        variant={ButtonVariant.WHITE}
                        text={t('filters_view.clear_all')}
                        onClick={handleClearAll}
                        outline={ButtonOutlineVariant.CLEARALL}
                        translate
                    />
                    <Button
                        className="FiltersView__footer__searchButton"
                        size={ButtonSize.LG}
                        variant={ButtonVariant.PRIMARY}
                        text={isLoading ? undefined : showButtonLabel}
                        onClick={handleFiltersSubmission}
                        disabled={matchingProductsCount === 0 || isLoading}
                        translate
                    >
                        {isLoading && <Loader />}
                    </Button>
                </div>
            </div>
        </SlidingModal>
    );
});
