import { CloudshelfEngineConfig, DisplayOnlyEngineConfig } from './types/config/CloudshelfEngineConfig';
import {
    Alignment,
    BannerDisplayMode,
    BarcodeDetectionMethod,
    CheckoutExperience,
    CheckoutFlow,
    ClearSalesAssistantRule,
    ECommercePlatform,
    EngineType,
    ImageAnchor,
    NonInteractiveCollectionType,
    SalesAssistantNameRule,
    Size,
    TileSize,
    VerticalAlignment,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';

export const EmptyDisplayOnlyConfig: DisplayOnlyEngineConfig = {
    displayLogo: false,
    collectionType: NonInteractiveCollectionType.Random,
    timePerPhoto: 0,
    maxProductsPerCollection: 0,
    maxPhotosPerProduct: 0,
    logoSize: Size.Small,
    includeProductName: false,
    includePrice: false,
    includeQRCode: false,
    includeStock: false,
};

export const EmptyConfig: CloudshelfEngineConfig = {
    productGridIncludeBrand: false,
    allowCustomCSS: false,
    plugins: [],
    barcodeResultAddToBasket: true,
    barcodeDetectionMethod: BarcodeDetectionMethod.None,
    barcodePrefix: '',
    barcodePostfix: '',
    deviceDPI: 96,
    screenSize: 7,
    tiles: [],
    banners: [],
    pdpBlocks: [],
    pdpIncludeSuggestedItems: false,
    attractLoop: {
        placementX: 0,
        includeHomeScreen: false,
        includeProductGroups: false,
        includeBanners: false,
        screenMinimumDuration: 0,
    },
    bannerDisplayRules: {
        display: {
            displayMode: BannerDisplayMode.NoBanners,
            duration: 0,
        },
    },
    showCloudshelfBranding: true,
    retailerRules: {
        allocateSalesToAssignedSalesPerson: false,
        clearSalesPerson: ClearSalesAssistantRule.Never,
        salesPersonName: SalesAssistantNameRule.FullName,
    },
    teamMembers: [],
    device: {
        id: '',
        name: '',
        owner: {
            id: '',
            name: '',
        },
        location: {
            id: '',
            name: '',
            address: '',
        },
        debugMode: false,
        isCloudshelfInternalDevice: false,
    },
    deviceMode: EngineType.Interactive,
    forceDebugOverlay: false,
    couponsEnabled: false,
    inMaintenanceMode: false,
    inStockLabel: '',
    limitedAvailabilityLabel: '',
    outOfStockLabel: '',
    soldOutLabel: '',
    displayInStockLabel: false,
    displayLimitedLabel: false,
    displayOutOfStockLabel: false,
    displaySoldOutLabel: false,
    showTotalStockCount: false,
    includeProductsInStock: true,
    includeProductsLimitedAvailability: false,
    includeProductsOutOfStock: false,
    filters: [],
    id: '',
    inactivityTimeout: 0,
    name: '',
    normalizedName: '',
    ownerId: '',
    ownerName: '',
    eCommercePlatform: ECommercePlatform.Unknown,
    checkoutFlow: CheckoutFlow.Unknown,
    checkoutExperience: CheckoutExperience.None,
    displayOnly: EmptyDisplayOnlyConfig,
    theme: {
        imageAnchor: ImageAnchor.Center,
        tileSize: TileSize.Square,
        logoSize: Size.Small,
        logoForceWhite: false,
        dynamicProductGridIncludeHero: true,
        dynamicProductGridIncludeSquare: true,
        dynamicProductGridIncludeTall: true,
        dynamicProductGridIncludeWide: true,
        collectionGridTileModifier: 1,
        productGridTileModifier: 1,
        attractScreen: {
            callToAction: '',
            callToActionAlignment: Alignment.Left,
            callToActionSize: Size.Regular,
            displayFrame: false,
            actionButton: '',
        },
        primaryColor: '',
        primaryContrastColor: '',
        mainTextColor: '',
        purchaseTextColor: '',
        attractLoopFontColor: '',
        attractLoopBackgroundColor: '',
        saleOriginalColour: '',
        saleColour: '',
        radius: { inputs: 0, drawer: 0, tiles: 0, modal: 0 },
        headingsFont: { isCustomFont: false, fontFamily: '', fontWeight: '' },
        subheadingsFont: { isCustomFont: false, fontFamily: '', fontWeight: '' },
        bodyFont: { isCustomFont: false, fontFamily: '', fontWeight: '' },
        attractLoopFontSize: Size.Regular,
        useProductAnimations: false,
        labelRules: [],
        labelVerticalAlignment: VerticalAlignment.Bottom,
        labelHorizontalAlignment: Alignment.Left,
    },
    trackers: [],
    updatedAt: '',
};
