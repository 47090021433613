import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';
export interface CircledCrossIconProps {
    onClick?: () => void;
}
const CircledCrossIcon: FunctionalComponentWithChildren<CircledCrossIconProps> = props => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={props.onClick}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2685 10.2686C2.1515 18.3856 2.1515 31.6144 10.2685 39.7314C18.3855 47.8484 31.6143 47.8484 39.7313 39.7314C47.8483 31.6144 47.8483 18.3856 39.7313 10.2686C31.6143 2.15161 18.3855 2.15161 10.2685 10.2686ZM17.6342 17.6343C16.824 18.4445 16.824 19.7704 17.6342 20.5806L22.0536 25L17.6342 29.4194C16.824 30.2296 16.824 31.5555 17.6342 32.3657C18.4444 33.1759 19.7703 33.1759 20.5805 32.3657L24.9999 27.9463L29.4193 32.3657C30.2296 33.1759 31.5554 33.1759 32.3656 32.3657C33.1758 31.5555 33.1758 30.2296 32.3656 29.4194L27.9462 25L32.3656 20.5806C33.1758 19.7704 33.1758 18.4445 32.3656 17.6343C31.5554 16.8241 30.2296 16.8241 29.4193 17.6343L24.9999 22.0537L20.5805 17.6343C19.7703 16.8241 18.4444 16.8241 17.6342 17.6343ZM13.2149 36.7851C19.7114 43.2817 30.2885 43.2817 36.7851 36.7851C43.2816 30.2886 43.2816 19.7114 36.7851 13.2149C30.2885 6.71834 19.7114 6.71834 13.2149 13.2149C6.71831 19.7114 6.71831 30.2886 13.2149 36.7851Z"
                fill="#5B5B5B"
            />
        </svg>
    );
};
export default CircledCrossIcon;
